import { useContext, useState, useEffect } from "react";
import { Divider, Modal, Space, Spin } from "antd";
import HeaderPage from "../../Organisms/HeaderPage/HeaderPage";
import Subtitle from "../../Atoms/Subtitle/Subtitle";
import { Link, useLocation } from "wouter";
import iconArrowRight from "../../../assets/arrow-right.svg";
import price_header_blue from "../../../assets/price_header_blue.png";
import "./Control.scss";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../auth/AuthContext";
import { types } from "../../../types/types";
import moment from "moment";
import { userRepo, userSubcriptionRepo, userTestRepo } from "../../../store";
import * as authHelper from "../../../utils/auth";

const Controls = () => {
  const { t } = useTranslation();
  const [, setLocation] = useLocation();
  const {
    dispatchUser,
    dispatchControl
  } = useContext(AuthContext);
  const [standardTests, setStandardTests] = useState([]);
  const [loadings, setLoadings] = useState();
  const [load, setLoad] = useState(true);
  const [arrayUser, setArrayUser] = useState([]);

  useEffect(() => {
    onLoadUser();
    return () => {
      setLoadings();
    };
  }, []);

  const onLoadUser = () => {
    userRepo.showUser().then(
      async ({ data }) => {
        arrayUser.push({
          name: data.name,
          userId: data.id,
          avatar: data.avatar,
          debug: data.permissions.length === 0 ? false : true,
        });

        authHelper.setLocalCurrentUser(
          data.name,
          data.last_name,
          data.avatar,
          data.email
        );
        const countTest = await userTestRepo.showUserTestbyUserId(data.id);
        const stantandadTest = await userSubcriptionRepo.stantandadTest();
        for (var l = 0; l < stantandadTest.data.data.length; l++) {
          const array = await userSubcriptionRepo.getUserSubcriptionByStatus({
              standardTestId: stantandadTest.data.data[l].id
            });
            const arraySubjects = array.data.data.filter(
                (obj, index) =>
                  array.data.data.findIndex((item) => item.id === obj.id) === index
              );
              setStandardTests((standardTests) => [
                ...standardTests,
                {
                  standardTestId: stantandadTest.data.data[l].id,
                  standardTestName: stantandadTest.data.data[l].name,
                  period: stantandadTest.data.data[l].period_id,
                  subjects: arraySubjects,
                  countTest: countTest.data.data.length,
                },
              ]);
            

          setLoad(false);
        }

        setLoadings(false);
      },
      // GET USER ERROR
      (error) => {
        setLoadings(false);
        if (error.response.status === 401) {
          Modal.error({
            title: t("error"),
            content: error,
          });
          setLocation("/login");
        } else {
          Modal.error({
            title: t("error"),
            content: error,
          });
        }
        if (error.response.status === 429) {      
          Modal.error({
            title: "Demasiados intentos.",
            content: error,
          });   
          setLocation("/dashboard");
        } 
      }
    );
  };

  const onSendControl = (id, name, standard_test_id, period, countTest) => {
    dispatchUser({
      type: types.login,
      payload: {
        name: arrayUser[0].name,
        userId: arrayUser[0].userId,
        avatar: arrayUser[0].avatar,
        debug: arrayUser[0].debug,
      },
    });
    dispatchControl({
      type: types.control,
      payload: {
        subjectId: id,
        subjectName: name,
        standartTestId: standard_test_id,
        periodId: period,
        countTest: countTest,
      },
    });
    setLocation("/temas");
  };

  const onSendHistorial = (id, name, standard_test_id, period, countTest) => {
    dispatchUser({
      type: types.login,
      payload: {
        name: arrayUser[0].name,
        userId: arrayUser[0].userId,
        avatar: arrayUser[0].avatar,
        debug: arrayUser[0].debug,
      },
    });
    dispatchControl({
      type: types.control,
      payload: {
        subjectId: id,
        subjectName: name,
        standartTestId: standard_test_id,
        periodId: period,
        countTest: countTest,
      },
    });
    setLocation("/historico");
  };

  const onSendStatistics = (id, name, standard_test_id, period, countTest) => {
    dispatchUser({
      type: types.login,
      payload: {
        name: arrayUser[0].name,
        userId: arrayUser[0].userId,
        avatar: arrayUser[0].avatar,
        debug: arrayUser[0].debug,
      },
    });
    dispatchControl({
      type: types.control,
      payload: {
        subjectId: id,
        subjectName: name,
        standartTestId: standard_test_id,
        periodId: period,
        countTest: countTest,
      },
    });
    setLocation("/estadistica");
  };

  const onSendIncrease = () => {
    userSubcriptionRepo.getUserSubcriptionDetails().then(
      ({ data }) => {
        userSubcriptionRepo
          .createUserSubcriptionIncrease({
            id: data[0].id,
          })
          .then((response) => {
            window.open(response.data.data[1], "_blank");
          });
      },
      (error) => {
        Modal.error({
          title: "Error message",
          content: error.response.data.message,
        });
      }
    );
  };

  return (
    <div className="page-controls max-width">
      <HeaderPage
        contentButton={t("contentButton")}
        routeButton="/dashboard"
        contentTitle={t("contentTitle")}
        contentSubtitle={t("contentSubtitle")}
      />

      {load === true ? (
        <div className="mt-60">
          <Space
            direction="horizontal"
            style={{ width: "100%", justifyContent: "center" }}
          >
            <Spin tip={t("loading")} size="large"></Spin>
          </Space>
        </div>
      ) : (
        <div className="mt-30">
          {standardTests.map((name, j) => (
            <div className="mt-20" key={`key-standard-grid-${j}`}>
              <Subtitle
                h4
                withDivider
                content={name.standardTestName}
                customClass=" c-blue-primary "
              />
              <Divider></Divider>
              <div className="matters-grid mt-20">
                {name.subjects.map((matters, i) => (
                  <div
                    className="price-table-item"
                    key={`key-subjects-grid-${i}`}
                  >
                    <div className="price-table-header">
                      <img
                        src={price_header_blue}
                        alt={t("alt")}
                        className="table-control-header-image"
                      />
                      <div className="table-header-content-control">
                        <Subtitle
                          h4
                          content={matters.name}
                          customClass={"c-white"}
                        />
                        <Subtitle h4 content="" customClass={"c-white"} />
                      </div>
                      <div className="price-table-list">
                        <ul>
                          {matters.replace_status === "Active" ||
                          (matters.replace_status === "Pause" && 
                          moment(matters.subscription_end).diff( moment(new Date().toISOString().slice(0, 10),"YYYY-MM-DD"), "days") >= 0)  ? (
                            <li className="montserrat-15">
                              <Link
                                href=""
                                onClick={() =>
                                  onSendControl(
                                    matters.id,
                                    matters.name,
                                    name.standardTestId,
                                    name.period,
                                    name.countTest
                                  )
                                }
                              >
                                <div className="card-matter-item mt-15">
                                  <img src={iconArrowRight} alt="Icon right" />
                                  <p className="montserrat-14 c-blue-second">
                                    {t("seesubject")}
                                  </p>
                                </div>
                              </Link>
                            </li>
                          ) : (
                            <li className="montserrat-15">
                              <Link href="/cuenta/editar">
                                <div className="card-matter-item mt-15">
                                  <img src={iconArrowRight} alt="Icon right" />
                                  <p className="montserrat-14 bold c-blue-second">
                                    {t("toSubscribe")}
                                  </p>
                                </div>
                              </Link>
                            </li>
                          )}
                          {name.countTest !== 0 ? (
                            <li className="montserrat-15">
                              <Link
                                href=""
                                onClick={() =>
                                  onSendHistorial(
                                    matters.id,
                                    matters.name,
                                    name.standardTestId,
                                    name.period,
                                    name.countTest
                                  )
                                }
                              >
                                <div className="card-matter-item mt-15">
                                  <img src={iconArrowRight} alt="Icon right" />
                                  <p className="montserrat-14 c-blue-second">
                                    {t("viewhistory")}
                                  </p>
                                </div>
                              </Link>
                            </li>
                          ) : (
                            <li className="montserrat-15 ">
                              <div className="card-matter-item mt-15">
                                <img src={iconArrowRight} alt="Icon right" />
                                <p className="montserrat-14 ">
                                  {t("viewhistory")}
                                </p>
                              </div>
                            </li>
                          )}
                          {name.countTest !== 0 ? (
                            <li className="montserrat-15">
                              <Link
                                href=""
                                onClick={() =>
                                  onSendStatistics(
                                    matters.id,
                                    matters.name,
                                    name.standardTestId,
                                    name.period,
                                    name.countTest
                                  )
                                }
                              >
                                <div className="card-matter-item mt-15">
                                  <img src={iconArrowRight} alt="Icon right" />
                                  <p className="montserrat-14 c-blue-second">
                                    {t("viewstatistics")}
                                  </p>
                                </div>
                              </Link>
                            </li>
                          ) : (
                            <li className="montserrat-15">
                              <div className="card-matter-item mt-15">
                                <img src={iconArrowRight} alt="Icon right" />
                                <p className="montserrat-14 ">
                                  {t("viewstatistics")}
                                </p>
                              </div>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Controls;
