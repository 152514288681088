import { useEffect, useContext } from "react";
import { Form, Input, Button, Modal, Select } from "antd";
import { useState } from "react";
import { useForm } from "../../../customHooks/useForm";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../auth/AuthContext";
import { PoweroffOutlined } from "@ant-design/icons";
import {
  addressRepo,
  cityRepo,
  stateRepo,
  townRepo,
  userSubcriptionRepo,
} from "../../../store/index";

const { Option } = Select;

const RegisterSubcriptionStep4 = ({
  next,
  prev,
  step3Data,
  step4Data,
  setStep4Data,
}) => {
  const { t } = useTranslation();
  const [loadings, setLoadings] = useState();
  const { user } = useContext(AuthContext);
  const paquete = localStorage.getItem("package");
  const [formValues, handleInputChange] = useForm({
    firstAddress: "",
    secondAddress: "",
    postalCode: "",
  });  

  const [state, setState] = useState("");

  const [city, setCity] = useState("");

  const [town, setTown] = useState("");

  const [stateAll, setStateAll] = useState("");

  const [cityAll, setCityAll] = useState("");

  const [townAll, setTownAll] = useState("");

  const { firstAddress, secondAddress, postalCode } = formValues;

  useEffect(() => {
    formValues.firstAddress =
      localStorage.getItem("firstAddress") === "null"
        ? ""
        : localStorage.getItem("firstAddress");
    formValues.secondAddress =
      localStorage.getItem("secondAddress") === "null"
        ? ""
        : localStorage.getItem("secondAddress");
    formValues.postalCode =
      localStorage.getItem("postalCode") === "null"
        ? ""
        : localStorage.getItem("postalCode");
    setTown(parseInt(localStorage.getItem("town")));
    setCity(parseInt(localStorage.getItem("city")));
    setState(parseInt(localStorage.getItem("state")));

    if (localStorage.getItem("town") !== "null") {
      onLoadUbication(parseInt(localStorage.getItem("town")));
    }
    onLoad();
    return () => {
      setLoadings();
    };
  }, []);

  const onLoadUbication = async (town_id) => {
    const responseTown = await townRepo.findTown(town_id);

    localStorage.setItem("townName", responseTown.data.data.name);
    const responseCity = await cityRepo.getCities(
      responseTown.data.data.city_id
    );

    localStorage.setItem("city", responseCity.data.data.id);

    localStorage.setItem("cityName", responseCity.data.data.name);

    const responseState = await stateRepo.findState(
      responseCity.data.data.state_id
    );

    localStorage.setItem("state", responseState.data.data.id);
    localStorage.setItem("stateName", responseState.data.data.name);
  };

  const handleInputSelectState = (value) => {
    setState(value);
    onCity(value);
  };

  const onCity = async (value) => {
    setCityAll("");
    const response = await stateRepo.findCity(value);
    setCityAll(response.data.data);
  };

  const handleInputSelectCity = (value) => {
    setCity(value);
    onTown(value);
  };

  const onTown = async (value) => {
    setTownAll("");
    const response = await cityRepo.findCity(value);
    setTownAll(response.data.data);
  };

  const handleInputSelectTown = (value) => {
    setTown(value);
  };

  const onLoad = async () => {
    const responseState = await stateRepo.showStates();
    setStateAll(responseState.data.data);    
    setLoadings(false);
  };

  const Prev = () => {
    localStorage.setItem("render", 0);
    localStorage.setItem("firstAddress", firstAddress);
    localStorage.setItem("secondAddress", secondAddress);
    localStorage.setItem("state", state);
    localStorage.setItem("city", city);
    localStorage.setItem("town", town);
    localStorage.setItem("postalCode", postalCode);
  };

  
  const finishStep4 = (e) => {
    setLoadings(true);
    addressRepo
      .createAddress({
        address_line_1: firstAddress,
        address_line_2: secondAddress,
        town_id: town,
        zip_code: postalCode,
      })
      .then(
        (response2) => {
          userSubcriptionRepo
            .createUserSubcription({              
              package_id: paquete,
              user_id: user.userId,
              subject_ids: step3Data,
            })
            .then((response3) => {
              // localStorage.removeItem("render");
              localStorage.removeItem("matters");
              localStorage.removeItem("active");
              localStorage.removeItem("firstAddress");
              localStorage.removeItem("secondAddress");
              localStorage.removeItem("state");
              localStorage.removeItem("city");
              localStorage.removeItem("town");
              localStorage.removeItem("stateName");
              localStorage.removeItem("cityName");
              localStorage.removeItem("townName");
              localStorage.removeItem("postalCode");
              localStorage.removeItem("package"); 
              next();            
              setLoadings(false);
            });
        },
        (error) => {
          setLoadings(false);
          Modal.error({
            title: "Error message",
            content: error.response.data.message,
          });
        } //error
      );
    setStep4Data(e); // Guardo los datos del segundo formulario en un estado
    
  };

  return (
    <Form
      onFinish={finishStep4}
      initialValues={{
        firstAddress: step4Data
          ? step4Data.firstAddress
          : localStorage.getItem("firstAddress") === "null"
          ? ""
          : localStorage.getItem("firstAddress"),
        secondAddress: step4Data
          ? step4Data.secondAddress
          : localStorage.getItem("secondAddress") === "null"
          ? ""
          : localStorage.getItem("secondAddress"),
        postalCode: step4Data
          ? step4Data.postalCode
          : localStorage.getItem("postalCode") === "null"
          ? ""
          : localStorage.getItem("postalCode"),
        state: step4Data
          ? step4Data.state
          : isNaN(parseInt(localStorage.getItem("state")))
          ? null
          : parseInt(localStorage.getItem("state")),
        city: step4Data
          ? step4Data.city
          : isNaN(parseInt(localStorage.getItem("city")))
          ? null
          : parseInt(localStorage.getItem("city")),
        town: step4Data
          ? step4Data.town
          : isNaN(parseInt(localStorage.getItem("town")))
          ? null
          : parseInt(localStorage.getItem("town")),
      }}
    >
      <h1 className="mb-15 mt-15 text-center">{t("contentPersonal")}</h1>

      <Form.Item
        label={t("firstAddress")}
        name="firstAddress"
        value={firstAddress}
        rules={[{ required: true, message: t("msgfirstAddress") }]}
      >
        <Input
          name="firstAddress"
          value={firstAddress}
          onChange={handleInputChange}
          placeholder={t("firstAddress")}
          size="large"
        />
      </Form.Item>

      <Form.Item
        label={t("secondAddress")}
        name="secondAddress"
        rules={[{ message: t("msgfirstAddress") }]}
      >
        <Input
          name="secondAddress"
          value={secondAddress}
          onChange={handleInputChange}
          placeholder={t("secondAddress")}
          size="large"
        />
      </Form.Item>

      <Form.Item
        label={t("state")}
        name="state"
        rules={[{ required: true, message: t("msgstate") }]}
      >
        <Select
          name="state"
          onChange={handleInputSelectState}
          placeholder={t("state")}
          size="large"
        >
          {stateAll.length ? (
            stateAll.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))
          ) : localStorage.getItem("stateName") === null ? (
            <Option>{t("msgSubcription13")}....</Option>
          ) : (
            <Input value={parseInt(localStorage.getItem("state"))}>
              {localStorage.getItem("stateName")}
            </Input>
          )}
        </Select>
      </Form.Item>

      <Form.Item
        label={t("city")}
        name="city"
        rules={[{ required: true, message: t("msgcity") }]}
      >
        <Select
          name="city"
          onChange={handleInputSelectCity}
          placeholder={t("city")}
          size="large"
        >
          {cityAll.length ? (
            cityAll.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))
          ) : localStorage.getItem("cityName") === null ? (
            <Option>{t("msgSubcription13")}...</Option>
          ) : (
            <Input value={parseInt(localStorage.getItem("city"))}>
              {localStorage.getItem("cityName")}
            </Input>
          )}
        </Select>
      </Form.Item>

      <Form.Item
        label={t("town")}
        name="town"
        rules={[{ required: true, message: t("msgtown") }]}
      >
        <Select
          name="town"
          onChange={handleInputSelectTown}
          placeholder={t("town")}
          size="large"
        >
          {townAll.length ? (
            townAll.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))
          ) : localStorage.getItem("townName") === null ? (
            <Option>{t("msgSubcription13")}...</Option>
          ) : (
            <Input value={parseInt(localStorage.getItem("town"))}>
              {localStorage.getItem("townName")}
            </Input>
          )}
        </Select>
      </Form.Item>

      <Form.Item
        label={t("postalCode")}
        name="postalCode"
        id="postalCode"
        rules={[
          { required: true, message: t("msgcode") },
          { pattern: "[0-9]+", message: t("msgnumeric") },
        ]}
      >
        <Input
          name="postalCode"
          value={postalCode}
          onChange={handleInputChange}
          placeholder={t("postalCode")}
          size="large"
        />
      </Form.Item>

      <div className="container-buttons-register">
        <Button
          onClick={() => {
            prev();
            Prev();
          }}
        >
          {t("btnback")}
        </Button>
        <Button
          type="primary"
          className="login-form-button"
          htmlType="submit"
          icon={<PoweroffOutlined />}
          loading={loadings}
        >
          {t("btncontinue")}
        </Button>
      </div>
    </Form>
  );
};

export default RegisterSubcriptionStep4;
