import { Button, Steps } from "antd";
import "./SuccessfulPayment.scss";
import { useEffect} from "react";
import Logo from "../../Atoms/Logo/Logo";
import { useTranslation } from "react-i18next";
import { useLocation } from "wouter";
const { Step } = Steps;

const SuccessfulPayment = () => {
  const { t, i18n } = useTranslation();
  const [, setLocation] = useLocation();
  const steps = [
    {
      title: t("Paquete"),
      status: "finish",
    },
    {
      title: t("Materia"),
      status: "finish",
    },
    {
      title: t("Tarjeta"),
      status: "finish",
    },
  ];

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem("lang"));
    localStorage.setItem("redirect", 1);
    localStorage.setItem("header", 1);    
  },[]);

  
  return (
    <div className="payment_container ">
      <Logo width={"200px"} height={"100%"} customClass="mb-10 text-center" />
      <div className="box-white">
        <Steps style={{ marginRight: 120 }} responsive={"600px"}>
          {steps.map((item) => (
            <Step key={item.title} title={item.title} status={item.status} />
          ))}
        </Steps>

        <h1 className="mb-10 text-center pt-30 pl-20 pr-20">{t("thanks")}</h1>
        <h1 className="mb-10 text-center pt-30 pl-20 pr-20">{t("confirm")}</h1>
        <p className="mb-10 text-center">{t("msg")}</p>
        <div className="text-center pb-30"> 
          <Button type="primary" onClick={() => setLocation("/dashboard")}>
            {t("btncontinue")}
          </Button>
        
        </div>
      </div>
    </div>
  );
};

export default SuccessfulPayment;
