import { useState } from "react";
import { Steps } from "antd";
import "./Register.scss";
import Logo from "../../Atoms/Logo/Logo";
import RegisterStep1 from "./RegisterStep1";
import { useTranslation } from "react-i18next";
const { Step } = Steps;

const Register = () => {
  const { t } = useTranslation();
  const [step1Data, setStep1Data] = useState(null);
  const [current, setCurrent] = useState(0);

  const steps = [
    {
      title: t("registro"),
      content: (
        <RegisterStep1          
          setStep1Data={setStep1Data}
          step1Data={step1Data}
        />
      ),
    },
    {
      title: "E-mail",       
    },
    {
      title: t("materia"),    
    },
    {
      title: t("direccion"),    
    },
    {
      title: t("Tarjeta"),   
    },    
  ];
 

  return (
    <div className="register_container">
      <div>
        <Logo width={"200px"} height={"100%"} customClass="mb-10 text-center" />
        <div className="box-white">
          <Steps current={current} className="mb-20" responsive={"600px"}>
            {steps.map((item) => (
              <Step key={item.title} title={item.title} style={{textAlign: "justify !important" }} />
            ))}
          </Steps>
          <div>{steps[current].content}</div>
        </div>
      </div>
    </div>
  );
};

export default Register;
