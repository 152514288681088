import { Button, Form, Modal } from "antd";
import React, { useState, useEffect } from "react";
import price_header_blue from "../../../assets/price_header_blue.png";
import Subtitle from "../../Atoms/Subtitle/Subtitle";
import { PoweroffOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { packageRepo, userRepo, userSubcriptionRepo } from "../../../store";

const AddPackageStep3 = ({
  next,
  prev,
  step3Data,
  setStep3Data,
  step2Data,
}) => {
  const { t } = useTranslation();
  const [matter, setMatter] = useState();
  const [quantity, setQuantity] = useState();
  const [chosenSubjects, setChosenSubjects] = useState();
  const [matterId, setMatterId] = useState([]);
  const [active, setActive] = useState([]);
  const [subjectsQuantity, setSubjectsQuantity] = useState();
  const [maxSubjects, setMaxSubjects] = useState(false);
  const [loadings, setLoadings] = useState();
  const [cleanup, setCleanup] = useState();

  const paquete = step2Data;
  const changeStyle = (id, i, isActive) => {
    if (!isActive) {
      active[i] = true;
      matterId.push(id);
      setQuantity(matterId.length);
    } else {
      active[i] = false;
      var index = matterId.indexOf(id);
      if (index === -1) {
        var indexS = matterId.indexOf(id.toString());
        matterId.splice(indexS, 1);
      } else {
        var indexS = matterId.indexOf(id);
        matterId.splice(indexS, 1);
      }

      setQuantity(matterId.length);
    }

    setMatterId(
      matterId.filter(
        (item) => item !== null || item !== "" || item !== undefined
      )
    );
  };

  useEffect(() => {
    onload();
    return () => {
      setCleanup();
    };
  }, []);

  const onload = async () => {
    const response = await packageRepo.getPackage(paquete);

    const maxSubjects = await packageRepo.getMaxSubsjects(
      response.data.data.standard_test_id
    );

    localStorage.getItem("matters");
    localStorage.getItem("active");
    setSubjectsQuantity(response.data.data.subjects_quantity);
    const response2 = await packageRepo.getSubjects(paquete);
    setQuantity(subjectsQuantity);

    if (localStorage.getItem("active") !== null) {
      var activeMatterId;
      var activeMatterId = localStorage.getItem("matters").split(",");
      var activeMatter = localStorage.getItem("active").split(",");

      setMatterId(activeMatterId);
      setQuantity(activeMatterId.length);
      for (var i = 1; i < activeMatter.length; i++) {
        if (activeMatter[i] === "true") {
          active[i] = true;
          setActive(active);
        } else {
          active[i] = false;
          setActive(active);
        }
      }
    } else {
      if (
        response.data.data.subjects_quantity <
        maxSubjects.data.data[0].maxSubjects
      ) {
        for (var i = 0; i < response2.data.data.data.length; i++) {
          active[i] = false;
          setActive(active);
        }
      } else {
        active[i] = true;
        for (var i = 0; i < response2.data.data.data.length; i++) {
          matterId.push(response2.data.data.data[i].id);
        }
        setQuantity(matterId.length);
        setActive(active);
        setMaxSubjects(true);
      }
    }
    setMatter({ matter: response2.data.data.data });
    setCleanup(false);
  };

  const finishStep3 = (e) => {
    if (
      matterId.length === subjectsQuantity ||
      chosenSubjects === subjectsQuantity
    ) {
      setLoadings(true);
      
      userRepo.showUser().then(
        async ({ data }) => {          
          userSubcriptionRepo
            .createUserSubcription({
              package_id: paquete,
              user_id: data.id,
              subject_ids: matterId,
            })
            .then((response3) => {
              setLoadings(false);
              next();
            });
        },
        (error) => {
          setLoadings(false);
          Modal.error({
            title: "Error message",
            content: error.response.data.message,
          });
        }
      );
    } else {
      Modal.info({
        title: t("info"),
        content: (
          <div>
            <p>
              {t("msgSubcription13")} {subjectsQuantity} {t("msgSubcription23")}
            </p>
          </div>
        ),
        onOk() {},
      });
    }
  };

  return (
    <Form onFinish={finishStep3}>
      <h1 className="mb-15 mt-15 text-center">{t("titleStep4")}</h1>
      <div className="page-controls max-width">
        <div className="mt-60">
          <h3 className="mb-15 mt-15 text-center">
            {t("msgSubcription13")} {subjectsQuantity} {t("msgSubcription23")}
          </h3>
          <div className="matters-grid">
            {typeof matter === "undefined" ? (
              <h1>{t('loading')}</h1>
            ) : (
              matter.matter.map((matters, i) =>
                active[i] === false ? (
                  <div className="table-item" key={matters.id}>
                    <div className="price-table-header">
                      <img
                        src={price_header_blue}
                        alt={t("alt")}
                        className="table-header-image"
                      />

                      <div className="table-header-content">
                        <Subtitle
                          h4
                          content={matters.name}
                          customClass={"c-white"}
                        />
                        <Subtitle
                          h4
                          content={matters.description}
                          customClass={"c-white"}
                        />
                      </div>

                      <div className="price-table-list">
                        <Form.Item name="paquete">
                          <Button
                            name="paquete"
                            value={matters.id}
                            onClick={() => changeStyle(matters.id, i)}
                          >
                            {t("selection")}
                          </Button>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="table-item-selection" key={matters.id}>
                    <div className="price-table-header">
                      <img
                        src={price_header_blue}
                        alt={t("alt")}
                        className="table-header-image"
                      />

                      <div className="table-header-content">
                        <Subtitle
                          h4
                          content={matters.name}
                          customClass={"c-white"}
                        />
                        <Subtitle
                          h4
                          content={matters.description}
                          customClass={"c-white"}
                        />
                      </div>
                      <div className="price-table-list">
                        {maxSubjects === true ? (
                          <Button type="primary" disabled>
                            {t("btndeseleccionado")}
                          </Button>
                        ) : (
                          <Button
                            type="primary"
                            onClick={() => changeStyle(matters.id, i, true)}
                          >
                            {t("btndeseleccionado")}
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                )
              )
            )}
          </div>
        </div>
      </div>
      <div className="container-buttons-register">
        <Button
          onClick={() => {
            prev();
          }}
        >
          {t("btnback")}
        </Button>
        <Button
          type="primary"
          htmlType="submit"
          icon={<PoweroffOutlined />}
          loading={loadings}
        >
          {t("btncontinue")}
        </Button>
      </div>
    </Form>
  );
};

export default AddPackageStep3;
