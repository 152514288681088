import { useEffect, useContext } from "react";
import "./PageHeader.scss";
import { Button } from "antd";
import logo from "../../../assets/logo.svg";
import MainMenu from "../../Molecules/MainMenu/MainMenu";
import MenuButtonTranslate from "../../Molecules/MenuButtonTranslate/MenuButtonTranslate";
import MainMenuInhabilitado from "../../Molecules/MainMenuInhabilitado/MainMenuInhabilitado";
import IconToggle from "../../Atoms/IconToggle/IconToggle";
import CardUser from "../../Atoms/CardUser/CardUser";
import CardUserInhabilitado from "../../Atoms/CardUserInhabilitado/CardUserInhabilitado";
import { Link } from "wouter";
import { useVisible } from "../../../customHooks/useVisible";
import Tooltip from "../../Molecules/Tooltip/Tooltip";
import MenuUser from "../../Molecules/MenuUser/MenuUser";
import { AuthContext } from "../../../auth/AuthContext";
import { userSubcriptionRepo } from "../../../store";
import { types } from "../../../types/types";
import { useLocation } from "wouter";
import moment from "moment";


const PageHeader = () => {
  
  const [, setLocation] = useLocation();
  const { subcription, dispatchSubcription } = useContext(AuthContext);
  const [visiblee, handleVisiblee, closeVisiblee] = useVisible();
  const [
    visibleNotificationn,
    handleVisibleNotificationn,
    closeVisibleNotificationn,
  ] = useVisible();

  const handleTooltipUserr = () => {
    if (visiblee) {
      closeVisiblee();
    } else {
      handleVisiblee();
      closeVisibleNotificationn();
    }
  };
  useEffect(() => {
    if (subcription === undefined) {
      var days;
      var status;
      userSubcriptionRepo.getUserSubcription().then(({ data }) => {
        for (var l = 0; l < data.length; l++) {
            if (data[l].status === "Trial") {
              var fecha1 = moment(data[l].subscription_start);
              var fecha2 = moment(
                new Date().toISOString().slice(0, 10),
                "YYYY-MM-DD"
              );
              days = fecha1.diff(fecha2, "days");
              status = data[l].status;
            }          
        }
        dispatchSubcription({
          type: types.subcription,
          payload: {
            active: 1,
            status: status,
            days: days,
          },
        });
      });
    }
  }, []);

 

  return (
    <header className="page-header_container">
      {localStorage.getItem("header") === "1" ? (
        <div className="page-header max-width">
          <Link href="/dashboard">
            <img src={logo} alt="Logo" className="page-header_logo" />
          </Link>
          <div className="page-header_col_one">
            <MainMenu />
            <MenuButtonTranslate/>
            <CardUser />

            <div onClick={() => handleTooltipUserr()}>
              <IconToggle width={"36px"} height={"27px"} fill={"#02023B"} />
              <Tooltip isVisible={visiblee}>
                <MenuUser />
              </Tooltip>
            </div>
          </div>
        </div>
      ) : (
        <div className="page-header max-width">
          <img src={logo} alt="Logo" className="page-header_logo" />
          <div className="page-header_col_one">
            <MainMenuInhabilitado />
            <CardUserInhabilitado />
            <div>
              <IconToggle width={"36px"} height={"27px"} fill={"#02023B"} />
              <Tooltip isVisible={visiblee}></Tooltip>
            </div>
          </div>
        </div>
      )}
      {subcription !== undefined ?
      subcription.status === "Trial" ? (
        <header
          className="page-header_container_banner"
          style={{ boxShadow: "-5px 4px 10px #080808" }}
        >
          <div className="page-header_banner max-width">
            <div>
              <h3 style={{ padding: 7 }}>
                Te quedan {subcription.days} días de período de prueba
              </h3>
            </div>
            <Button type="primary"  onClick={() => 
                        setLocation("/cuenta/editar")
                      }>
              Suscribirse
            </Button>
          </div>
        </header>
      ) : null : null}
    </header>
  );
};

export default PageHeader;
