import React, { useEffect,  useReducer, Suspense } from 'react'
import ReactGA from "react-ga4";
import { GOOGLE_ANALYTICS } from '../src/Config/config'
import CookieConsent, {
  Cookies,
  getCookieConsentValue
} from 'react-cookie-consent'
import { useTranslation } from "react-i18next";
import AppRouter from './AppRouter'
import { AuthContext } from '../src/auth/AuthContext'
import { authReducer } from '../src/auth/authReducer'
import { controlReducer } from '../src/auth/controlReducer'
import { historicalReducer } from '../src/auth/historicalReducer'
import { wrongReducer } from '../src/auth/wrongReducer'
import { typeTestReducer } from '../src/auth/typeTestReducer'
import { resultReducer } from '../src/auth/resultReducer'
import { subcriptionReducer } from '../src/auth/subcriptionReducer'
import { pageHeaderReducer } from '../src/auth/pageHeaderReducer'


const init = () => {
  return { logged: false, name: '', userId: '', avatar: '', header: 1, debug: false }
}

const App = () => {
  const { t } = useTranslation();
  const [user, dispatchUser] = useReducer(authReducer, {}, init)
  const [typeTest, dispatchType] = useReducer(typeTestReducer)
  const [result, dispatchResult] = useReducer(resultReducer)
  const [control, dispatchControl] = useReducer(controlReducer)
  const [historical, dispatchHistorical] = useReducer(historicalReducer)
  const [wrong, dispatchWrong] = useReducer(wrongReducer)
  const [subcription, dispatchSubcription] = useReducer(subcriptionReducer)
  const [pageHeader, dispatchPageHeader] = useReducer(pageHeaderReducer)  
  const TRACKING_ID = GOOGLE_ANALYTICS.googleAnalytics

  const handleAcceptCookie = () => {
    if (TRACKING_ID) {
      ReactGA.initialize(TRACKING_ID);
    }
  }

  const handleDeclineCookie = () => {
    //remove google analytics cookies
    Cookies.remove('_ga')
    Cookies.remove('_gat')
    Cookies.remove('_gid')
  }

  useEffect(() => {
    const isConsent = getCookieConsentValue()
    if (isConsent === 'true' || localStorage.getItem('banner') === 'true') {
      handleAcceptCookie()
    }
  }, [])

  return (
    <Suspense fallback=''>
      <AuthContext.Provider
        value={{
          user,
          dispatchUser,
          typeTest,
          dispatchType,
          control,
          dispatchControl,
          historical,
          dispatchHistorical,
          wrong,
          dispatchWrong,
          subcription,
          dispatchSubcription,
          pageHeader,
          dispatchPageHeader,
          result,
          dispatchResult,
        }}
      >
        <AppRouter />
        {localStorage.getItem('banner') === 'true' ?
        null
        :
        <CookieConsent
          cookieName='cookieConsent'
          location='bottom'
          buttonText={t('cookieButtonText')}
          onAccept={handleAcceptCookie}
          onDecline={handleDeclineCookie}
          expires={150}
          enableDeclineButton='true'
          declineButtonText={t('cookieDeclineButtonText')}
          style={{
            backgroundColor: '#ffff',
            fontFamily: 'Montserrat, sans-serif',
            fontSize: '14px',
            outline: 0,
            boxShadow: '-5px 4px 20px #080808',
            borderRadius: '5px',
            zIndex: 1
          }}
          buttonStyle={{
            borderRadius: '40px',
            width: '230px',
            height: '37px !important',
            padding: '0 2rem',
            cursor: 'pointer',
            fontFamily: 'Montserrat, sans-serif',
            border: '2px solid transparent',
            backgroundColor: '#FAE65C',
            color: '#02023B',
            fontSize: '14px'
          }}
          declineButtonStyle={{
            borderRadius: '40px',
            height: '37px !important',
            padding: '0 2rem',
            cursor: 'pointer',
            fontFamily: 'Montserrat, sans-serif',
            border: '2px solid #0C4393',
            backgroundColor: '#ffffff',
            fontSize: '14px',
            color: '#0C4393'
          }}
        >
          <p style={{ color: '#080808' }}>
          {t('cookieParagraph1')}{' '}
          </p>
          <p style={{ color: '#080808' }}>
          {t('cookieParagraph2')} &quot;{t('cookieButtonText')}&quot;  {t('cookieParagraph3')}
          </p>
        </CookieConsent>
        }
      </AuthContext.Provider>
    </Suspense>
  )
}

export default App
