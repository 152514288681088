import { useState } from "react";
import { Steps } from "antd";
import "./AddPackage.scss";
import AddPackageStep2 from "./AddPackageStep2";
import AddPackageStep3 from "./AddPackageStep3";
import AddPackageStep4 from "./AddPackageStep4";
import { useTranslation } from "react-i18next";
const { Step } = Steps;

const AddPackage = () => {
  const { t } = useTranslation();
  const [step2Data, setStep2Data] = useState(null);
  const [step3Data, setStep3Data] = useState(null);
  const [step4Data, setStep4Data] = useState(null);
  const [current, setCurrent] = useState(0);
  const next = () => setCurrent(current + 1);
  const prev = () => setCurrent(current - 1);

  const steps = [    
    {
      title: t("Paquete"),
      content: (
        <AddPackageStep2
          next={next}
          step2Data={step2Data}
          setStep2Data={setStep2Data}
        />
      ),
    },
    {
      title: t("Materia"),
      content: (
        <AddPackageStep3
          next={next}
          prev={prev}
          step2Data={step2Data}
          step3Data={step3Data}
          setStep3Data={setStep3Data}          
        />
      ),
    },
    {
      title: t("summary"), 
      content: (
        <AddPackageStep4
          next={next}
          prev={prev}
          step3Data={step3Data}
          step4Data={step4Data}
          setStep4Data={setStep4Data}          
        />
      ),
    },
    {
      title: t("Tarjeta"),   
    }, 
  ];

  return (
    <div className="ChangePackage_container">
      
        <div className="box-white">
          <Steps current={current} responsive={"600px"}>
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
          <div>{steps[current].content}</div>
        </div>
      </div>
    
  );
};

export default AddPackage;
