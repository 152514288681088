import Repository from './repository.js'
export default class UserSubcriptionRepository extends Repository {
  constructor (apis) {
    super(apis)
  }

  createUserSubcription (params = {}) {
    const {
      package_id,
      user_id,
      subject_ids,
    } = params

    return this.$axios.post('api/user-subscriptions', {      
      package_id: parseInt(package_id),
      user_id: user_id,
      subject_ids: subject_ids,
    })
  }

  createUserSubcriptionIncrease (params = {}) {
    const { id, isDiscount } = params
    return this.$axios.post('api/user-subscriptions-increase', {
      id: id,
      isDiscount: isDiscount
    })
  }

  editFeedBack (params = {}) {
    const { id, feedback } = params
    return this.$axios
      .post('api/user-subscriptions/feedback', { id: id, feedback: feedback })
      .then(({ data }) => data)
  }

  find (id) {
    return this.$axios.get('api/user-subscriptions/find/'+id)
  }

  getUserSubcription () {
    return this.$axios.get('api/user-subscriptions').then(({ data }) => data)
  }

  getUserSubcriptionDetails () {
    return this.$axios
      .get('api/user-subscriptions/details')
      .then(({ data }) => data)
  }

  async getUserSubcriptionIncrease () {
    return await this.$axios
      .get('api/user-subscriptions/increase-checkout-link')
      .then(({ data }) => data)
  }

  getUserSubcriptionByIdIncrease (params = {}) {
    return this.$axios
      .get('api/user-subscriptions/subscription-id/increase-checkout-link', {
        params: params
      })
      .then(({ data }) => data)
  }

  async queryStatusIncrease (increase_subscription_id) {
    return await this.$axios
      .get('api/user-subscriptions/increase/' + increase_subscription_id)
      .then(({ data }) => data)
  }

  status (id) {
    return this.$axios.post('api/user-subscriptions/set-status-pending', {
      id: id
    })
  }

  statusSubscription (params = {}) {
    return this.$axios.get('api/user-subscriptions/set-status', {
      params: params
    })
  }

  stantandadTest () {
    return this.$axios.get('api/user-subscriptions/standard-test')
  }

  getUserSubcriptionByStatus (params = {}) {
    return this.$axios
      .get('api/user-subscriptions/by-status', {
        params: params
      })      
  }

  applyPromocode (useSubscriptionId, params = {}) {
    const {
      code
    } = params;
    return this.$axios
      .post('api/user-subscriptions/'+useSubscriptionId+'/verify-promo-code', {
         code: code
        })
      .then(({ data }) => data)
  }

  applyDiscount(useSubscriptionId, params = {}) {
    const {
      code
    } = params;
    return this.$axios
      .post('api/user-subscriptions/'+useSubscriptionId+'/subscribe', {
        code: code
        })
      .then(({ data }) => data)
  }

}
