import { Button, Form, Modal } from "antd";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import price_header_yellow from "../../../assets/price_header_yellow.png";
import price_header_blue from "../../../assets/price_header_blue.png";
import icon_checklist from "../../../assets/icon-checklist.svg";
import Subtitle from "../../Atoms/Subtitle/Subtitle";
import { packageRepo } from "../../../store";
import { useLocation } from "wouter";
const RegisterSubcriptionPackageStep2 = ({ next, step2Data, setStep2Data }) => {
  const { t } = useTranslation();
  
  const [, setLocation] = useLocation();
  const [packages, setPackages] = useState([]);
  const [paquete, setPaquete] = useState([]);
  const [quantity, ] = useState(1);
  const [active, setActive] = useState([]);
  const [, setCleanup] = useState();

  const info = () => {
    Modal.info({
      title: t("info"),
      content: (
        <div>
          <p> {t("modalPaquete")}</p>
        </div>
      ),
      onOk() {},
    });
  };

  const infoPaqueteLength = () => {
    Modal.info({
      title: t("info"),
      content: (
        <div>
          <p>{t("modalPaqueteLength")}</p>
        </div>
      ),
      onOk() {},
    });
  };

  useEffect(() => {
    onLoad();
    return () => {
      setCleanup();
    };
  }, []);

  const onLoad = async () => {
    const responsePackages = await packageRepo.getAll();

    for (var i = 0; i < responsePackages.data.data.length; i++) {
      setPackages((packages) => [...packages, responsePackages.data.data[i]]);
      active[i] = false;
      setActive(active);
      paquete[i] = "";
      setPaquete(paquete);
    }

    setCleanup(false);
  };

  const changeStyle = (id, i) => {
    if (active[i] === true) {
      active[i] = false;
      setActive([...active]);
      paquete[i] = "";
      setPaquete([...paquete]);
    } else {
      active[i] = true;
      setActive([...active]);

      paquete[i] = id;
      setPaquete([...paquete]);
    }
  };

  const arrayLength = () => {
    var count = 0;
    for (var i = 0; i < paquete.length; i++) {
      if (paquete[i] !== "") {
        count += 1;
      }
    }
    return count;
  };

  const finishStep2 = (e) => {
    for (var i = 0; i < paquete.length; i++) {
      if (paquete[i] !== "") {
        setStep2Data(paquete[i]); // Guardo los datos del tercer formulario en un estado
        next(); // Voy al siguiente paso
      }
    }
  };

  return (
    <Form onFinish={finishStep2}>
      <h1 className="mb-15 mt-15 text-center">{t("titleStep3")}</h1>
      <div className="page-controls max-width">
        <div className="mt-60">
          <div className="matters-grid">
            {typeof packages === "undefined" ? (
              <h1>{t('loading')}</h1>
            ) : (
              packages.map((matter, i) =>
                !active[i] ? (
                  i % 2 !== 0 ? (
                  <div className="table-item" key={matter.id}>
                    <div className="price-table-header">
                      <img
                        src={price_header_yellow}
                        alt={t("alt")}
                        className="table-header-image"
                      />

                      <div className="table-header-content">
                        <Subtitle
                          h4
                          content={matter.name}
                          customClass={"c-blue-primary"}
                        />
                        <Subtitle
                          h4
                          content={matter.description}
                          customClass={"c-blue-primary"}
                        />
                       
                      </div>

                      <div className="price-table-list">
                      <ul>
                        <li
                          key={`key-price-table-carousel-li-${i}`}
                          className="montserrat-15"
                        >
                          <span>
                            <img src={icon_checklist} alt="Icono checklist" />
                          </span>{" "}
                          {matter.duration} {t("dias")} $ {matter.price} (CLP)
                        </li>
                      </ul>
                      </div>
                        <Form.Item name="paquete">
                          <Button
                            name="paquete"
                            value={matter.id}
                            onClick={() => changeStyle(matter.id, i)}
                          >
                            {t("selection")}
                          </Button>
                        </Form.Item>
                      
                    </div>
                  </div>
                ):(
                  
                  <div className="table-item" key={matter.id}>
                  <div className="price-table-header">
                    <img
                      src={price_header_blue}
                      alt={t("alt")}
                      className="table-header-image"
                    />

                    <div className="table-header-content">
                      <Subtitle
                        h4
                        content={matter.name}
                        customClass={"c-white"}
                      />
                      <Subtitle
                        h4
                        content={matter.description}
                        customClass={"c-white"}
                      />
                      
                    </div>

                    <div className="price-table-list">
                    <ul>
                        <li
                          key={`key-price-table-carousel-li-${i}`}
                          className="montserrat-15"
                        >
                          <span>
                            <img src={icon_checklist} alt="Icono checklist" />
                          </span>{" "}
                          {matter.duration} {t("dias")} $ {matter.price} (CLP)
                        </li>
                      </ul>
                      </div>
                      <Form.Item name="paquete">
                        <Button
                          name="paquete"
                          value={matter.id}
                          onClick={() => changeStyle(matter.id, i)}
                        >
                          {t("selection")}
                        </Button>
                      </Form.Item>
                    
                  </div>
                </div>

                )) : (
                  i % 2 !== 0 ?
                   <div className="table-item-selection" key={matter.id}>
                    <div className="price-table-header">
                      <img
                        src={price_header_yellow}
                        alt={t("alt")}
                        className="table-header-image"
                      />

                      <div className="table-header-content">
                        <Subtitle
                          h4
                          content={matter.name}
                          customClass={"c-blue-primary"}
                        />
                        <Subtitle
                          h4
                          content={matter.description}
                          customClass={"c-blue-primary"}
                        />
                        
                      </div>

                      <div className="price-table-list">
                      <ul>
                    <li
                      key={`key-price-table-grid-li-${i}`}
                      className="montserrat-15"
                    >
                      <span>
                        <img src={icon_checklist} alt="Icono checklist" />
                      </span>
                      {matter.duration} {t("dias")} $ {matter.price} (CLP)
                    </li>
                  </ul>
                </div>
                        <Form.Item name="paquete">
                          <Button
                            type="primary"
                            name="paquete"
                            value={matter.id}
                            onClick={() => changeStyle(matter.id, i)}
                          >
                            {t("btndeseleccionado")}
                          </Button>
                        </Form.Item>
                      
                    </div>
                  </div>
                :<div className="table-item-selection" key={matter.id}>
                <div className="price-table-header">
                  <img
                    src={price_header_blue}
                    alt={t("alt")}
                    className="table-header-image"
                  />

                  <div className="table-header-content">
                    <Subtitle
                      h4
                      content={matter.name}
                      customClass={"c-white"}
                    />
                    <Subtitle
                      h4
                      content={matter.description}
                      customClass={"c-white"}
                    />
                  </div>

                  <div className="price-table-list">
                  <ul>
                    <li
                      key={`key-price-table-grid-li-${i}`}
                      className="montserrat-15"
                    >
                      <span>
                        <img src={icon_checklist} alt="Icono checklist" />
                      </span>
                      {matter.duration} {t("dias")} $ {matter.price} (CLP)
                    </li>
                  </ul>
                </div>
                    <Form.Item name="paquete">
                      <Button
                        type="primary"
                        name="paquete"
                        value={matter.id}
                        onClick={() => changeStyle(matter.id, i)}
                      >
                        {t("btndeseleccionado")}
                      </Button>
                    </Form.Item>
                  
                </div>
              </div> 
            )))}
          </div>
        </div>
      </div>

      <div className="container-buttons-register" >
      <Button
          onClick={() => 
            setLocation("/login")
          }
        >
          {t("btnback")}
        </Button>
        {arrayLength() === 0 ? (
          <Button type="primary" onClick={infoPaqueteLength}>
            {t("btn")}
          </Button>
        ) : arrayLength() === quantity ? (
          <Button type="primary" htmlType="submit">
            {t("btn")}
          </Button>
        ) : (
          <Button type="primary" onClick={info}>
            {t("btn")}
          </Button>
        )}
      </div>
    </Form>
  );
};

export default RegisterSubcriptionPackageStep2;
