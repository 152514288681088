import { useState, useEffect, useContext } from "react";

import HeaderPage from "../../Organisms/HeaderPage/HeaderPage";

import CardTableHistorico from "../../Molecules/CardTableHistorico/CardTableHistorico";

import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../auth/AuthContext";
import { userTestRepo } from "../../../store";

import "./Historical.scss";
import moment from "moment";

const Historical = () => {
  const { t } = useTranslation();
  const {  control } = useContext(AuthContext);
  const [dataControl, setDataControl] = useState([]);
  const [loadings, setLoadings] = useState();
  localStorage.removeItem("render");

  const columns = [
    {
      title: "",
      dataIndex: "select",
      key: "select",
      defaultSortOrder: "descend",
    },
    {
      title: t("date"),
      dataIndex: "date",
      key: "date",
      defaultSortOrder: "descend",
      sorter: (a, b) =>  new Date(a.date) - new Date(b.date),
    },
    {
      title:  t("note") +" %",
      dataIndex: "note",
      key: "note",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.note - b.note,
    },
    {
      title: t("Total"),
      dataIndex: "total",
      key: "total",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.total - b.total,
    },
    {
      title: t("correctQuestion"),
      dataIndex: "correct",
      key: "correct",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.correct - b.correct,
    },
    {
      title: t("result"),
      dataIndex: "result",
      key: "result",
    },
    {
      title: t("tiempo") +" min",
      dataIndex: "time",
      key: "time",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.time - b.time
    },    
  ];

  useEffect(() => {
    onLoad();
    return () => {
      setLoadings();
    };
  }, []);

  const onLoad = async () => {
    var setTimeHours;
    var setTime;
    var setTimeSeconds; 
    const response = await userTestRepo.getUserTest(control.subjectId);

    for (var i = 0; i < response.data.data.length; i++) {
      
      if(moment(response.data.data[i].end).diff(  moment(response.data.data[i].start), "hours") > 0){
        setTimeHours=moment(response.data.data[i].end).diff(  moment(response.data.data[i].start), "hours");
      }else{
          setTimeHours=0;
      }
      if (
        moment(response.data.data[i].end).diff(
          moment(response.data.data[i].start),
          "minutes"
        ) %
          60 >
        10
      ) {
        setTime =
          moment(response.data.data[i].end).diff(
            moment(response.data.data[i].start),
            "minutes"
          ) % 60;
      }
      if (
        moment(response.data.data[i].end).diff(
          moment(response.data.data[i].start),
          "minutes"
        ) %
          60 <
        10
      ) {
        setTime =
          "0" +
          (moment(response.data.data[i].end).diff(
            moment(response.data.data[i].start),
            "minutes"
          ) %
            60);
      }
      if (
        moment(response.data.data[i].end).diff(
          moment(response.data.data[i].start),
          "seconds"
        ) %
          60 >
        10
      ) {
        setTimeSeconds =
          moment(response.data.data[i].end).diff(
            moment(response.data.data[i].start),
            "seconds"
          ) % 60;
      }
      if (
        moment(response.data.data[i].end).diff(
          moment(response.data.data[i].start),
          "seconds"
        ) %
          60 <
        10
      ) {
        setTimeSeconds =
          "0" +
          (moment(response.data.data[i].end).diff(
            moment(response.data.data[i].start),
            "seconds"
          ) %
            60);
      }

      if (response.data.data[i].type === "custom") {
        
        setDataControl((dataControl) => [
          ...dataControl,
          {
            key: response.data.data[i].id,
            date: response.data.data[i].fecha,
            note:
              Math.round(
                (100 * response.data.data[i].total_correct_choices) /
                  response.data.data[i].total_test_stems
              ),
            total: response.data.data[i].total_test_stems,
            correct: response.data.data[i].total_correct_choices,
            result:
              Math.round(
                (100 * response.data.data[i].total_correct_choices) /
                  response.data.data[i].total_test_stems
              ) >= 50
                ? t("aprobado")
                : t("reprobado"),
            time: response.data.data[i].min_passed
              
          },
        ]);
      }
      
    }
    setLoadings(false);
  };

  return (
    <div className="max-width">
        <HeaderPage
          withMenu
          contentButton={t("contentButtonHistorical")}
          routeButton="/control"
          contentTitle={t("contentTitleControl")}
          contentSubtitle={
            t("contentSubtitleHistorical") + " " + control.subjectName
          }
        />
     
        <CardTableHistorico           
          customClass="mt-20 cols-2"
          columns={columns}
          data={dataControl}
        />
      
    </div>
  );
};

export default Historical;
