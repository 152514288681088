import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Card,
  Checkbox,
  Divider,
  Form,
  Input,
  Modal,
  Pagination,
  Radio,
  Space,
  Table,
  Tag,
  message,
} from "antd";
import { useForm } from "../../../customHooks/useForm";
import { useLocation } from "wouter";
import { useTranslation } from "react-i18next";
import { CheckBox } from "../../Atoms/CheckBox/CheckBox";
import { AuthContext } from "../../../auth/AuthContext";
import { Timer } from "../../Molecules/Timer/Timer";
import LinearProgress from '@mui/material/LinearProgress';
import { types } from "../../../types/types";
import {
  userRepo,
  gradeRepo,
  userTestRepo,
  userTestStemRepo,
} from "../../../store";
import "./EssaysWrong.scss";

const EssaysWrong = () => {
  const { t } = useTranslation();

  const columns = [
    {
      title: "#",
      dataIndex: "questionNumber",
      key: "questionNumber",
    },
    {
      title: t("mark"),
      dataIndex: "mark",
      key: "mark",
    },
    {
      title: t("answered"),
      dataIndex: "answered",
      key: "answered",
    },
  ];

  const [, setLocation] = useLocation();
  const { user } = useContext(AuthContext);
  const { control, dispatchControl, wrong, dispatchUser, dispatchResult } =
    useContext(AuthContext);
  const { typeTest } = useContext(AuthContext);

  const [formValues, handleInputChange] = useForm({
    valueComment: "",
  });

  const { valueComment } = formValues;

  const [question, setQuestion] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadings, setLoadings] = useState(false);
  const [totalStem, setTotalStem] = useState();
  const [answer, setAnswer] = useState([]);
  const [dataMark, setDataMark] = useState([]);
  const [context, setContext] = useState(true);
  const [render, setRender] = useState(false);
  const [explanition, setExplanition] = useState(false);
  const [active, setActive] = useState(false);
  const [activeTime, setActiveTime] = useState(false);
  const [value, setValue] = useState([]); //radio group
  const [startTime, setStarTime] = useState();
  const [endDate, setEndDate] = useState();
  const [stems, setStems] = useState([]);
  const [themeStats, setThemeStats] = useState([]);
  const [correctStemsChoice, setCorrectStemsChoice] = useState([]);
  const [score, setScore] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisibleSend, setModalVisibleSend] = useState(false);
  const [modalVisibleInfo, setModalVisibleInfo] = useState(true);
  const [modalVisibleComment, setModalVisibleComment] = useState(false);
  const [alphabet, setAlphabet] = useState([
    "A)",
    "B)",
    "C)",
    "D)",
    "E)",
    "F)",
    "G)",
    "H)",
    "I)",
  ]);

  //Variable encargada de la paginación
  const [current, setCurrent] = useState(1);
  let setSize = false;
  const key = "updatable";
  const { TextArea } = Input;

  const time = new Date();
  const segundos = wrong.seconds;
  time.setSeconds(time.getSeconds() + parseInt(segundos));

  const [pressed, setPressed] = React.useState(false);

  React.useEffect(() => {
    window.onpopstate = (e) => {
      if (localStorage.getItem("header") === "0") {
        handleModalSendOk(true);
        localStorage.setItem("header", 1);
      }
    };
    window.onbeforeunload = (e) => {
      if (localStorage.getItem("header") === "0") {
        handleModalSendOk(true);
        localStorage.setItem("header", 1);
      }
    };
  });

  let node = React.createRef();
  useEffect(() => {
    renderMath();
  });

  useEffect(() => {
    if(render === false){

    answer.map((itemAns)=> {
      if( itemAns.value.length === 0){
          dataMark
          .filter((item) => item.key === itemAns.id)
          .map((item) =>
            replaceItem(itemAns.id- 1, {
              key: item.key,
              questionNumber: (
                <a name={itemAns.id} id={itemAns.id} onClick={() => onChangeLogic(itemAns.id)}>
                  {item.questionNumber}
                </a>
              ),
              mark: <Checkbox name={itemAns.id} id={itemAns.id} checked={true}></Checkbox>,
              answered: <Checkbox name={itemAns.id} id={itemAns.id} checked={false}></Checkbox>,
            })
          );
      }
   }
  ) }
  }, [answer]);

  const renderMath = () => {
    window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub, node.current]);
  };

  useEffect(() => {
    onLoad();
    setStarTime(time);
   
    if (typeTest.type === "official") {
      onLoadScore();
    }
    return () => {
      setLoadings();
    };
  }, []);

  const info = () => {
    message.info(
      t("msgAlert"),
      0
    );
  };

  const onLoad = async () => {
    info();
    const response = await wrong.url;
    if (response.data.data.length === 0) {
      Modal.info({
        title: t("info"),
        content: "No tiene Preguntas Incorrectas",
      });
      setLocation("/dashboard");
    } else {
      var themeNow = [];
      setModalVisibleInfo(true);
      setActiveTime(true);
      setTotalStem(response.data.data.length);
      for (var i = 0; i < response.data.data.length; i++) {
        setValue((value) => [...value, { id: null, value: null }]);
        setDataMark((dataMark) => [
          ...dataMark,
          {
            key: i + 1,
            questionNumber: i + 1,
            mark: <Checkbox name={i + 1} id={i + 1} checked={false}></Checkbox>,
            answered: <Checkbox name={i + 1} id={i + 1} checked={false}></Checkbox>,
          },
        ]);
        themeNow[i] = response.data.data[i].theme_id;
        var correctStems = 0;

        for (var s = 0; s < response.data.data[i].stem_choices.length; s++) {
          if (response.data.data[i].stem_choices[s].key_indicator === true) {
            correctStems = correctStems + 1;
          }
        }
        setCorrectStemsChoice((correctStemsChoice) => [
          ...correctStemsChoice,
          {
            id: response.data.data[i].id,
            correctStems: correctStems,
            theme: response.data.data[i].theme_id,
          },
        ]);

        setQuestion((question) => [
          ...question,
          {
            id: response.data.data[i].id,
            statement: response.data.data[i].statement,
            solution_body: response.data.data[i].solution_body,
            theme_id: response.data.data[i].theme_id,
            stem_context_id: response.data.data[i].stem_context_id,
            answer: [response.data.data[i].stem_choices],
            context:
              response.data.data[i].stem_context === null
                ? null
                : response.data.data[i].stem_context.body,
            multioption: response.data.data[i].is_multi_choice,
            url_source:
              response.data.data[i].url_source !== null
                ? response.data.data[i].url_source
                : null,
          },
        ]);
      } //total stem

      let resultTheme = themeNow.filter((item, index) => {
        return themeNow.indexOf(item) === index;
      });

      for (var q = 0; q < resultTheme.length; q++) {
        setThemeStats((themeStats) => [
          ...themeStats,
          {
            theme_id: resultTheme[q],
            correct_quantity: 0,
            unanswered_quantity: 0,
            incorrect_quantity: 0,
          },
        ]);
      }

      //setModalVisibleInfo(false);
      setLoading(false);
      setLoadings(false);
      setActiveTime(true);
      setStarTime(new Date());
      setEndDate(time);
    }
  }; //fin de la funcion onload

  const onLoadScore = async () => {
    const responseGrade = await gradeRepo.showGrades({
      subject_id: control.subjectId,
    });

    for (var g = 0; g < responseGrade.data.data.length; g++) {
      setScore((score) => [
        ...score,
        {
          grade: responseGrade.data.data[g].grade,
          quantity: responseGrade.data.data[g].good_answers_quantity,
        },
      ]);
    }
  }; //fin del LoadScore

  //metodo de las preguntas marcadas
  const onCheckboxClicked = (idx, isChecked) => {
    if (!isChecked) {
      setActive(false);
      dataMark
        .filter((item) => item.key === idx)
        .map((item) =>
          replaceItem(idx - 1, {
            key: item.key,
            questionNumber: (
              <a name={idx} id={idx} onClick={() => onChangeLogic(idx)}>
                {item.questionNumber}
              </a>
            ),
            mark: <Checkbox name={idx} id={idx} checked={false}></Checkbox>,
            answered: <Checkbox name={idx} id={idx} checked={true}></Checkbox>,
          })
        );
    } else {
      setActive(true);
      dataMark
        .filter((item) => item.key === idx)
        .map((item) =>
          replaceItem(idx - 1, {
            key: item.key,
            questionNumber: (
              <a
                name={idx}
                id={idx}
                onClick={() => onChangeLogic(idx)}
                className="subrayado"
              >
                {item.questionNumber}
              </a>
            ),
            mark: (
              <Checkbox name={idx} id={idx} checked={true}>
              </Checkbox>
            ),
            answered: <Checkbox name={idx} id={idx} checked={false}></Checkbox>,
          })
        );
    }
  };
  //metodo de las preguntas respondidas
  const onClickedAnswered = (idx) => {   
      dataMark
        .filter((item) => item.key === idx)
        .map((item) =>
          replaceItem(idx - 1, {
            key: item.key,
            questionNumber: (
              <a name={idx} id={idx} onClick={() => onChangeLogic(idx)}>
                {item.questionNumber}
              </a>
            ),
            mark: <Checkbox name={idx} id={idx} checked={false}></Checkbox>,
            answered: <Checkbox name={idx} id={idx} checked={true}></Checkbox>,
          })
        );    
    };

  const replaceItemStems = (indexToReplace, newItem) => {
    const updatedItems = [...stems];
    updatedItems.splice(indexToReplace, 1, newItem);
    return setStems(updatedItems);
  };
  //método que add/delete la pregunta marcada
  const replaceItem = (indexToReplace, newItem) => {
    const updatedItems = [...dataMark];
    updatedItems.splice(indexToReplace, 1, newItem);
    return setDataMark(updatedItems);
  };

  //Método de las respuestas simples
  const onChange = (e) => {    
    onClickedAnswered(e.target.name);
    replaceAnswer(e.target.name - 1, {
      id: e.target.name,
      value: e.target.value,
    });
    replaceSelect(e.target.name - 1, e.target.value);
  };
  //método de respuestas multiples
  const onChangeMulti = (e, current) => { 
    onClickedAnswered(current);  
    var index = answer.map(answers => answers.id).indexOf(current);
    if(index === -1){      
      replaceAnswerMulti(current - 1, { id: current, value: e }); 
    }else{
        replaceAnswerMulti(index, { id: current, value: e }); 
    }
    value[current - 1].value = e;
  };

  const replaceAnswerMulti = (indexToReplace, newItem) => {    
      const updatedItems = [...answer];    
      updatedItems.splice(indexToReplace, 1, newItem);        
      return setAnswer(updatedItems);
  };
  
  //metodo que guarda las respuestas seleccionadas
  const replaceSelect = (indexToReplace, newItem) => {
    const updatedItems = [...value];
    updatedItems.splice(indexToReplace, 1, newItem);
    return setValue(updatedItems);
  };
  //método que guarda las respuestas simples
  const replaceAnswer = (indexToReplace, newItem) => {
    const updatedItems = [...answer];
    updatedItems.splice(indexToReplace, 1, newItem);
    return setAnswer(updatedItems);
  };

  const onChangeExplanition = (e) => {
    setExplanition(e.target.checked);
  };
  //método contador de respuestas por tema
  const onThemeStats = (
    key,
    themeId,
    summation,
    summationNull,
    sumationIncorrect
  ) => {
    replaceThemeStats(key, {
      theme_id: themeId,
      correct_quantity: summation,
      unanswered_quantity: summationNull,
      incorrect_quantity: sumationIncorrect,
    });
  };
  //método que guarda la cantidad de respuestas buenas
  const replaceThemeStats = (indexToReplace, newItem) => {
    const updatedItems = [...themeStats];
    updatedItems.splice(indexToReplace, 1, newItem);
    return setThemeStats(updatedItems);
  };

  const handleModal = (modalVisible) => {
    setModalVisible(modalVisible);
  };

  const sizeChangeLogic = (current, size) => {
    setSize = true;
    setCurrent(1);
  };

  const onChangeLogic = (current) => {
    setCurrent(current);
  };

  const onChangeCurrent = (current) => {
    if( current < totalStem){
      setCurrent(current + 1);
    }
  };

  const onChangeContext = (e) => {
    setContext(e.target.checked);
  };

  const deleteDuplicate = () => {
    let hash = {};
    let answers = answer.filter((o) =>
      hash[o.id] ? false : (hash[o.id] = true)
    );
    return setAnswer(answers);
  };

  const answerNull = () => {
    for (var i = 0; i < totalStem; i++) {
      if (!answer.some((item) => item.id === i + 1)) {
        answer.unshift({ id: i + 1, value: null });
      }
    }
    let answerSort = answer.sort((ans1, ans2) =>
      ans1.id > ans2.id ? 1 : ans1.id < ans2.id ? -1 : 0
    );
    return setAnswer(answerSort);
  };

  const handleModalSendOk = (modalVisibleSend) => {   
    setRender(true);
    deleteDuplicate();
    answerNull();
    setModalVisibleSend(modalVisibleSend);
    onSend();
  };

  const handleModalSend = (modalVisibleSend) => {
    deleteDuplicate();
    setModalVisibleSend(modalVisibleSend);
  };

  const handleModalCommentOk = (
    modalVisibleComment,
    userId,
    subjectId,
    id,
    valueComment
  ) => {
    setModalVisibleComment(modalVisibleComment);
    userRepo
      .sendEmail({
        userId: userId,
        subjectId: subjectId,
        stemId: id,
        commment: valueComment,
      })
      .then(
        (response) => {
          Modal.info({
            title: t("info"),
            content: response.message,
          });
        },
        // COOKIE ERROR
        (error) => {
          Modal.error({
            title: t("error"),
            content: error.response.statusText,
          });
        }
      );
  };

  const handleModalComment = (modalVisibleComment) => {
    setModalVisibleComment(modalVisibleComment);
  };

  const onSubmitExam = () => {
    setRender(true);
    deleteDuplicate();
    answerNull();
    onSend();
  };

  const onSend = () => {
    var totalCorrect = 0;
    var totalIncorrect = 0;
    var correctAnswer = 0;
    var unanswered = 0;
    var incorrectAnswer = 0;
    var correctAnswerId;
    var scoreTotal = 0;
    message.destroy();
    dispatchUser({
      type: types.header,
      payload: {
        header: 1,
      },
    });

    localStorage.setItem("header", 1);
    for (var k = 0; k < totalStem; k++) {
      correctAnswer = 0;
      incorrectAnswer = 0;
      if (answer[k].value !== null) {
        for (var l = 0; l < question[k].answer[0].length; l++) {
          if (typeof answer[k].value === "object") {
            for (var m = 0; m < answer[k].value.length; m++) {
              correctAnswerId = question[k].id;
              if (
                question[k].answer[0][l].key_indicator === true &&
                answer[k].value[m] === question[k].answer[0][l].id
              ) {
                correctAnswer += 1;
                stems.unshift({
                  is_correct: true,
                  is_marked: dataMark[k].mark.props.checked,
                  order: k + 1,
                  stem_id: question[k].id,
                  stem_choice_id: question[k].answer[0][l].id,
                });
              }
              if (
                question[k].answer[0][l].key_indicator === false &&
                answer[k].value[m] === question[k].answer[0][l].id
              ) {
                incorrectAnswer += 1;
                stems.unshift({
                  is_correct: false,
                  is_marked: dataMark[k].mark.props.checked,
                  order: k + 1,
                  stem_id: question[k].id,
                  stem_choice_id: question[k].answer[0][l].id,
                });
              }
            }
          }
          if (
            question[k].answer[0][l].key_indicator === true &&
            question[k].answer[0][l].id === answer[k].value
          ) {
            totalCorrect += 1;
            stems.unshift({
              is_correct: true,
              is_marked: dataMark[k].mark.props.checked,
              order: k + 1,
              stem_id: question[k].id,
              stem_choice_id: question[k].answer[0][l].id,
            });
            for (var i = 0; i < themeStats.length; i++) {
              if (themeStats[i].theme_id === question[k].theme_id) {
                onThemeStats(
                  i,
                  themeStats[i].theme_id,
                  (themeStats[i].correct_quantity += 1),
                  themeStats[i].unanswered_quantity,
                  themeStats[i].incorrect_quantity
                );
              }
            }
          }
          if (
            question[k].answer[0][l].key_indicator === false &&
            question[k].answer[0][l].id === answer[k].value
          ) {
            totalIncorrect += 1;
            stems.unshift({
              is_correct: false,
              is_marked: dataMark[k].mark.props.checked,
              order: k + 1,
              stem_id: question[k].id,
              stem_choice_id: question[k].answer[0][l].id,
            });

            for (var i = 0; i < themeStats.length; i++) {
              if (themeStats[i].theme_id === question[k].theme_id) {
                onThemeStats(
                  i,
                  themeStats[i].theme_id,
                  themeStats[i].correct_quantity,
                  themeStats[i].unanswered_quantity,
                  (themeStats[i].incorrect_quantity += 1)
                );
              }
            }
          }
        }
      }
      if (answer[k].value === null  || answer[k].value.length === 0) {
        unanswered += 1;
        stems.unshift({
          is_correct: false,
          is_marked: dataMark[k].mark.props.checked,
          order: k + 1,
          stem_id: question[k].id,
          stem_choice_id: null,
        });

        for (var i = 0; i < themeStats.length; i++) {
          if (themeStats[i].theme_id === question[k].theme_id) {
            onThemeStats(
              i,
              themeStats[i].theme_id,
              themeStats[i].correct_quantity,
              (themeStats[i].unanswered_quantity += 1),
              themeStats[i].incorrect_quantity
            );
          }
        }
      } //preguntas con respuesta null
      if (
        correctAnswer === correctStemsChoice[k].correctStems &&
        correctAnswerId === correctStemsChoice[k].id
      ) {
        totalCorrect += 1;
        for (var j = 0; j < themeStats.length; j++) {
          if (themeStats[j].theme_id === question[k].theme_id) {
            onThemeStats(
              j,
              themeStats[j].theme_id,
              (themeStats[j].correct_quantity += 1),
              themeStats[j].unanswered_quantity,
              themeStats[j].incorrect_quantity
            );
          }
        }
      }
      if (
        correctAnswer !== correctStemsChoice[k].correctStems &&
        correctAnswerId === correctStemsChoice[k].id
      ) {
        totalIncorrect += 1;
        for (var j = 0; j < themeStats.length; j++) {
          if (themeStats[j].theme_id === question[k].theme_id) {
            onThemeStats(
              j,
              themeStats[j].theme_id,
              themeStats[j].correct_quantity,
              themeStats[j].unanswered_quantity,
              (themeStats[j].incorrect_quantity += 1)
            );
          }
        }
      }
    }
    if (typeTest.type === "official") {
      //buscar valor de score
      for (var s = 0; s < score.length; s++) {
        if (score[s].quantity === totalCorrect) {
          scoreTotal = score[s].grade;
        }
      }
    }
    dispatchResult({
      type: types.result,
      payload: {
        start: startTime,
        end: new Date(),
        score: typeTest.type === "official" ? scoreTotal : 0,
        total: totalStem,
        totalCorrect: totalCorrect,
        totalIncorrect: totalIncorrect,
        totalUnanswered: unanswered,
      },
    });

    userTestRepo
      .createUserTest({
        start: startTime,
        end: new Date(),
        total_test_stems: totalStem,
        total_correct_choices: totalCorrect,
        type: typeTest.type,
        score: typeTest.type === "official" ? scoreTotal : 0,
        user_id: user.userId,
        standard_test_id: control.standartTestId,
        theme_ids: themeStats,
        total_correct_quantity: totalCorrect,
        total_incorrect_quantity: totalIncorrect,
        total_unanswered_quantity: unanswered,
      })
      .then(
        (data) => {
          dispatchControl({
            type: types.control,
            payload: {
              userTestId: data.data.id,
            },
          });
          userTestStemRepo
            .createUserTestStem(data.data.id, { stems: stems })
            .then(
              (response) => {},
              (error) => {
                Modal.error({
                  title: t("error"),
                  content: error.response.statusText,
                });
              }
            );
        },
        // COOKIE ERROR
        (error) => {
          Modal.error({
            title: t("error"),
            content: error.response.statusText,
          });
        }
      );

    Modal.info({
      content: (
        <div>
          <div>
            {typeTest.type === "official" ? (
              <p>
                {t("score")} {scoreTotal}
              </p>
            ) : (
              <p> {t("score")} </p>
            )}
          </div>
          <br></br>
          <div>
            <p>
              {" "}
              {t("questionCorrect")} : {totalCorrect}
            </p>
            <p>
              {" "}
              {t("incorrect")} : {totalIncorrect}
            </p>
            <p>
              {" "}
              {t("questionTotal")} : {totalStem}
            </p>
            <p>
              {" "}
              {t("questionUnanswered")} : {unanswered}
            </p>
          </div>
        </div>
      ),
      onOk() {},
    });
    setLocation("/resultado");
  };

  return (
    <div className="max-width unselectable">
      <div className="main-ensayos-button ">
        {activeTime ? (
          /*variables que calculan los segundo para el Timer*/
          <Button>
            <Timer expiryTimestamp={time} onSubmitExam={onSubmitExam} />
          </Button>
        ) : null}
      </div>

      <div className="main-wrong-grid" ref={node}>
        <Card
          className="mt-20 cols-1 "
          title={control.subjectName}
          bordered={false}
        >
          {typeof question != "undefined" ? (
            question
              .filter((item, i) => i + 1 === current)
              .map((item, i) => (
                <Card key={`key-essay-${current}`}>
                  <div className="pxy-10">
                    <div className="pl-10">
                      <Button type="primary">
                        {t("titleQuestion")} {current}
                      </Button>
                      {item.context !== null ? (
                        <div>
                          <div className="row">
                            <div className="col-80">
                              <p className="c-black mt-10">
                                {item.stem_context_title}
                              </p>
                            </div>
                            <div className="col-20">
                              <Form.Item>
                                <Checkbox
                                  name="context"
                                  onChange={onChangeContext}
                                  defaultChecked={true}
                                >
                                  {t("showContext")}
                                </Checkbox>
                              </Form.Item>
                            </div>
                          </div>
                          {context === true ? (
                            <div
                              className="main-ensayos-context mt-10"
                              dangerouslySetInnerHTML={{
                                __html: item.context,
                              }}
                            />
                          ) : null}
                          <Divider />
                        </div>
                      ) : null}

                      <div
                        className="mt-10"
                        dangerouslySetInnerHTML={{ __html: item.statement }}
                      />

                      <div className="row">
                        <div className="col-80">
                          {item.multioption === false ? (
                            <p className="c-black mt-10"> {t("option")} </p>
                          ) : (
                            <p className="c-black mt-10">
                              {" "}
                              {t("multioption")}{" "}
                            </p>
                          )}
                        </div>
                        <div className="col-20  mt-10">
                          {dataMark[current - 1].mark.props.checked ? (
                            <CheckBox
                              initialState={true}
                              value={dataMark[current - 1]}
                              id={current}
                              name={current}
                              onChange={onCheckboxClicked}
                            />
                          ) : (
                            <CheckBox
                              initialState={false}
                              value={dataMark[current - 1]}
                              id={current}
                              name={current}
                              onChange={onCheckboxClicked}
                            />
                          )}{" "}
                          {t("mark")}
                        </div>
                      </div>

                      {item.multioption === 0 ? (
                        <Radio.Group
                          onChange={onChange}
                          name={current}
                          value={value[current - 1]}
                        >
                          <Space direction="vertical">
                            {item.answer.length ? (
                              item.answer[0].map((Item1, index) => (
                                <Tag
                                  className="edit-tag"
                                  key={`key-tag-${Item1.id}`}
                                >
                                  <> {alphabet[index]} </>
                                  <Radio
                                    key={`key-radio-group-${Item1.id}`}
                                    value={Item1.id}
                                  >
                                    <div
                                      className="mt-20 stem"
                                      style={{   whiteSpace: "initial", textAlign:"justify"}}
                                      dangerouslySetInnerHTML={{
                                        __html: Item1.body.replace(
                                          /<a /g,
                                          '<a target="blank" '
                                        ),
                                      }}
                                    />
                                  </Radio>
                                </Tag>
                              ))
                            ) : (
                              <h1>{t('loading')}</h1>
                            )}
                          </Space>
                        </Radio.Group>
                      ) : (
                        <Checkbox.Group
                          onChange={(e) => onChangeMulti(e, current)}
                          name={current}
                          value={value[current - 1].value}
                        >
                          <Space direction="vertical">
                            {item.answer.length ? (
                              item.answer[0].map((Item1, i) => (
                                <Tag
                                className="edit-tag"
                                key={`key-tag-${Item1.id}`}
                              >
                                <> {alphabet[i]} </>
                                <Checkbox
                                  key={`key-checkbox-${Item1.id}`}
                                  value={Item1.id}
                                >
                                  <div
                                  className="mt-20 stem"
                                  style={{   whiteSpace: "initial", textAlign:"justify"}}
                                    dangerouslySetInnerHTML={{
                                      __html: Item1.body.replace(
                                        /<a /g,
                                        '<a target="blank" '
                                      ),
                                    }}
                                  />
                                </Checkbox>
                                </Tag>
                              ))
                            ) : (
                              <h1>{t('loading')}</h1>
                            )}
                          </Space>
                        </Checkbox.Group>
                      )}
                       <Divider></Divider>
                      <div>
                        <Button
                          type="primary"
                          id={i + 1}
                          name={i + 1}
                          style={{ float: "right" }}
                          onClick={() => onChangeCurrent(current)}
                        >
                         {t('btnNext')}
                        </Button>
                      </div >
                      <div className="mt-20 cols-1 "></div>

                      <Divider />
                    </div>
                  </div>
                </Card>
              ))
          ) : (
            <h1>Loading..</h1>
          )}

          <div className="pxy-10">
            <div className="main-ensayos-pagination-questions mt-10 mb-10">
              <Pagination
                current={current}
                onShowSizeChange={sizeChangeLogic}
                onChange={onChangeLogic}
                showSizeChanger={false}
                total={dataMark.length * 10}
              />
            </div>

            <div className="main-ensayos-buttons-questions">
              {loadings === false ? (
                pressed ? (
                  <Button type="primary" onClick={handleModalSend(true)}>
                    {t("btnfinalizar")}{" "}
                  </Button>
                ) : (
                  <Button type="primary" onClick={() => handleModalSend(true)}>
                    {t("btnfinalizar")}{" "}
                  </Button>
                )
              ) : null}
            </div>
          </div>
        </Card>

        <Card className="mt-20 cols-2" title={t("status")} bordered={false}>
          <div className="pxy-30 ">
            <Table
              size="small"
              pagination={true}
              columns={columns}
              dataSource={dataMark}
            />
          </div>
        </Card>
      </div>
      <Modal
        title={t("msginfo")}
        style={{ top: 70 }}
        closable={false}
        open={modalVisibleInfo}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
      >
         <h3>{t("paragraph1")}</h3>
        <br/>
        <p >{t("paragraph10")}</p>
        <p className="mt-5">{t("paragraph2")}</p>
        <p className="mt-5">{t("paragraph3")}</p>
        <p className="mt-5">{t("paragraph4")}</p>
        <p className="mt-5">{t("paragraph5")}</p>
        {control.standartTestId === 2 ? (
          <p className="mt-5">{t("paragraphRule")}</p>
        ) : (
          <p className="mt-5">{t("paragraphRulePSU")}</p>
        )}  
        <br/>
        <Divider></Divider>
        <h3>{t("paragraph6")}</h3>
        <br/>
        <p>{t("paragraph7")}</p>
        <p className="mt-5">{t("paragraph8")}</p>
        <p className="mt-5">{t("paragraph9")}</p>
         <br/>
        <LinearProgress color="primary"/>
        <br></br>
        {loadings === false ?    
        <span>
        <Button type="primary"  style={{float: "right"}} onClick={() =>  setModalVisibleInfo(false)}>{t('btncontinue')}</Button>
         <br></br>
        </span>
        
        : 
        null
        }
        <br></br>
      </Modal>

      <Modal
        title={t("info")}
        style={{ top: 20 }}
        open={modalVisibleSend}
        onOk={() => handleModalSendOk(false)}
        onCancel={() => handleModalSend(false)}
      >
        {t("sendTest")}
      </Modal>
    </div>
  );
};

export default EssaysWrong;
