import React, {  useEffect, useState, useContext } from "react";
import { AuthContext } from "../../../auth/AuthContext";
import "./CardAverageList.scss";
import { Card } from "antd";
import { useTranslation } from "react-i18next";
import { userSubjectStatsRepo } from "../../../store";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const CardAverageList = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const [subjectList, setSubjectList] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [scoreControl, ] = useState([]);
  const [scoreEnsayo, ] = useState([]);
  const [, setCleanup] = useState();
  const [sendingRequest, setSendingRequest] = useState(false);

  useEffect(() => {
    asyncFetch();
    return () => {
      setCleanup();
      setSubjectList([]);
      setSubjects([]);
    };
  }, []);

  const asyncFetch = () => {
    if (sendingRequest === false) {
      setSendingRequest(true);
      userSubjectStatsRepo
        .getUserSubjectStatsAverageList(user.userId)
        .then(({ data }) => {
          for (let j = 0; j < data.length; j++) {
            subjects.push(data[j].subject.name);

            if (data[j].user_test_type === "custom") {
              scoreControl.push(Math.round(data[j].sum / data[j].count));
            }
            if (data[j].user_test_type === "official") {
              scoreEnsayo.push(Math.round(data[j].sum / data[j].count));
            }
          }

          let resultSubjects = subjects.filter((item, index) => {
            return subjects.indexOf(item) === index;
          });

          setSubjectList(resultSubjects);
        });
    }
    setCleanup(false);
  };
  if (subjectList.length !== 0) {
    var chartOptions = {
      chart: {
        type: "bar",
      },
      credits: {
        enabled: false,
      },
      title: {
        text: null,
      },
      xAxis: {
        categories: subjectList,
      },
      yAxis: {
        min: 0,
        title: {
          text: "Promedio %",
        },
      },
      legend: {
        reversed: false,
      },
      
      series: [
        {
          name: t("modalityControl"),
          data: scoreControl,
          fontFamily: "Montserrat, sans-serif",
          fontSize:6
        },
        {
          name: t("modalityEnsayo"),
          data: scoreEnsayo,
          fontFamily: "Montserrat, sans-serif",
          fontSize:6
        },
      ],
    };
  }

  return (
    <Card title={t("cardAverageList")}  bordered={false}>
      {subjectList.length !== 0 ? (
        <HighchartsReact
          className="pxy-10"
          highcharts={Highcharts}
          options={chartOptions}
        />
      ) : null}
    </Card>
  );
};

export default CardAverageList;
