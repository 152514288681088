import { useState, useEffect, useContext } from "react";
import { useForm } from "../../../customHooks/useForm";
import { Button,  Divider, Form, Input, Modal, Select, Table } from "antd";
import {  FormOutlined, CheckOutlined } from "@ant-design/icons";
import { Link, useLocation } from "wouter";
import TitleForm from "../../Atoms/TitleForm/TitleForm";
import { useTranslation } from "react-i18next";
import {
  userRepo,
  cityRepo,
  stateRepo,
  addressRepo,
  townRepo,
  userSubcriptionRepo,
  packageRepo,
} from "../../../store";
import "./FormEditProfileUser.scss";
import { AuthContext } from "../../../auth/AuthContext";
import { types } from "../../../types/types";
import moment from "moment";
const { Option } = Select;

const FormProfileUser = () => {
  const { t } = useTranslation();

  const columns = [
    {
      title: "",
      dataIndex: "select",
      key: "select",
    },
    {
      title: t("plan"),
      dataIndex: "plan",
      key: "plan",
    },
    {
      title: t("statusProfile"),
      dataIndex: "state",
      key: "state",
    },
    {
      title: t("start"),
      dataIndex: "start",
      key: "start",
    },
    {
      title: t("end"),
      dataIndex: "end",
      key: "end",
    },
    {
      title: t("quantitySubjects"),
      dataIndex: "quantitySubjects",
      key: "quantitySubjects",
    },
    {
      title: t("materia"),
      dataIndex: "materia",
      key: "materia",
    },
    {
      title: t("action"),
      dataIndex: "action",
      key: "action",
    },
  ];
  const [modalVisibleActivate, setModalVisibleActivate] = useState(false);
  const [modalVisibleSubscription, setModalVisibleSubscription] =
    useState(false);
  const { user, dispatchSubcription } = useContext(AuthContext);

  const [userSubcriptions, setUserSubcriptions] = useState([]);
  const [User, setUser] = useState({
    id: "",
    address_id: "",
    name: "",
    last_name: "",
    password: "",
    email: "",
    tax_id: "",
    phone: "",
    timezone: "",
    language: "",
    terms_and_conditions: "",
  });

  const arraySubscription = [];

  const [, setCleanup] = useState();

  const [passwordNew, setPasswordNew] = useState();
  const [confirm, setConfirm] = useState();

  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [town, setTown] = useState("");
  const [, setLocation] = useLocation();

  const [stateOld, setStateOld] = useState("");
  const [cityOld, setCityOld] = useState("");
  const [townOld, setTownOld] = useState("");

  const [stateAll, setStateAll] = useState("");
  const [cityAll, setCityAll] = useState("");
  const [townAll, setTownAll] = useState("");

  const [editPersonal, setPersonal] = useState(false);
  const [editDirection, setDirection] = useState(false);
  const [editUbication, setUbication] = useState(false);
  const [editPassword, setPassword] = useState(false);
  const [Address, setAddress] = useState({
    address_line_1: "",
    address_line_2: "",
    town_id: "",
    zip_code: "",
  });
  //Carga  de datos
  useEffect(() => {
    onLoadTableSubscription();
    onLoad();
    return () => {
      setCleanup();
    };
  }, []);

  const onLoad = async () => {
    const response = await userRepo.showUser();
    setUser({
      address_id: response.data.address_id,
      name: response.data.name,
      last_name: response.data.last_name,
      tax_id: response.data.tax_id,
      email: response.data.email,
      phone: response.data.phone,
    });
    if (response.data.address !== null) {
      setAddress({
        address_line_1: response.data.address.address_line_1,
        address_line_2: response.data.address.address_line_2,
        town_id: response.data.address.town_id,
        zip_code: response.data.address.zip_code,
      });

      const responseTown = await townRepo.findTown(
        response.data.address.town_id
      );

      setTownOld(responseTown.data.data.name);
      User.town_id = responseTown.data.data.id;

      setCityOld(responseTown.data.data.city.name);

      const responseState = await stateRepo.findState(
        responseTown.data.data.city.state_id
      );
      setStateOld(responseState.data.data.name);
    }
  
    const responseStateAll = await stateRepo.showStates();
    setStateAll(responseStateAll.data.data);
    setCleanup(false);
  };

  const onLoadTableSubscription = () =>{
    userSubcriptionRepo.getUserSubcriptionDetails().then(({ data }) => {
      data.map((item, i) => {
        var subjects;
        packageRepo
          .getMaxSubsjectsProfile(item.package.standard_test_id)
          .then(({ data }) => {
            subjects = quantitySubjects(
              item.package.subjects_quantity,
              data[0].maxSubjects,
              item.subjects
            );
            var textAction = "";
            if (item.status === "Active") {
              textAction = "Cancelar";
            }
            if (item.status === "Pause") {
              textAction = "Activar";
            }
            if (
              item.status === "Pending" ||
              item.status === "Pending_payment" ||
              item.status === "Trial" ||
              item.status === "Created"
            ) {
              textAction = "Suscribirse";
            }

            setUserSubcriptions((userSubcriptions) => [
              ...userSubcriptions,
              {
                key: i,
                plan: user.debug === true ? item.id +' '+ item.package.name : item.package.name,
                state: item.status,
                start: moment(item.subscription_start)
                  .utc()
                  .format("DD/MM/YYYY"),
                end: moment(item.subscription_end).utc().format("DD/MM/YYYY"),
                quantitySubjects: item.package.subjects_quantity,
                materia: subjects,
                action: (
                  <Link href="" onClick={() => onClickAction(item)}>
                    <div className="card-matter-item">
                      <p className="montserrat-14 bold c-blue-second">
                        {textAction}
                      </p>
                    </div>
                  </Link>
                ),
              },
            ]);
          });
      });
    });
  }
  
  const {
    id,
    name,
    last_name,
    email,
    phone,
    tax_id,
    password,
  } = User;

  const { address_line_1, address_line_2, town_id, zip_code } = Address;

  const [formValues, handleInputChange] = useForm();

  const onLoadUbication = async (town_id) => {
    const responseTown = await townRepo.findTown(town_id);
    setTownOld(responseTown.data.data.name);
    User.town_id = responseTown.data.data.id;

    setCityOld(responseTown.data.data.city.name);

    const responseState = await stateRepo.findState(
      responseTown.data.data.city.state_id
    );
   
    setStateOld(responseState.data.data.name);
  };

  const handleInputSelectState = (value) => {
    setState(value);
    onCity(value);
  };

  const onCity = async (value) => {
    setCityAll("");
    const response = await stateRepo.findCity(value);
    setCityAll(response.data.data);
  };

  const handleInputSelectCity = (value) => {
    setCity(value);
    onTown(value);
  };

  const onTown = async (value) => {
    setTownAll("");
    const response = await cityRepo.findCity(value);
    setTownAll(response.data.data);
  };

  const handleInputSelectTown = (value) => {
    setTown(value);
  };

  const onCancel = () => {
    setLocation("/dashboard");
  };

  const handlePersonalActive = () => {
    setPersonal(true);
  };

  const handlePersonal = () => {
    setPersonal(false);
  };

  const handlePasswordActive = () => {
    setPassword(true);
  };

  const handlePassword = () => {
    setPassword(false);
  };

  const handleDirectionActive = async () => {
   
    setDirection(true);
  };

  const handleDirection = () => {
    setDirection(false);
  };

  const handleUbicationActive = () => {

    setUbication(true);
  };

  const handleUbication = () => {
    setUbication(false);
  };

  const quantitySubjects = (quantity, maxQuantity, subjects) => {
    var arraySubjects = "";

    if (quantity < maxQuantity) {
      for (var j = 0; j < subjects.length; j++) {
        arraySubjects += subjects[j].name + ",";
      }
    } else {
      arraySubjects = "Todas";
    }
    return arraySubjects;
  };

  const subjectNames = (quantity, subjects) => {
    var arraySubjects = "";

    for (var j = 0; j < subjects.length; j++) {
      arraySubjects += subjects[j].name + ",";
    }

    return arraySubjects;
  };

  const onClickAction = (item) => {
    var subjectsNames = subjectNames(
      item.package.subjects_quantity,
      item.subjects
    );
    if (item.status === "Active") {
      arraySubscription.push([
        item.id,
        moment(item.subscription_end).utc().format("MM/DD/YYYY"),
        item.package.name,
        subjectsNames,
      ]);
      unsubscribe();
    }
    if (item.status === "Pause") {
      activateSubscription(item.id);
    }
    if (item.status === "Pending" || item.status === "Pending_payment" || item.status === "Trial" || item.status === "Created") {
      dispatchSubcription({
        type: types.subcriptionById,
        payload: {
          id: item.id,
          status: item.status,
            
        },
      });
     setLocation('/factura/detalles');
    }     
  };

  const unsubscribe = () => {
    dispatchSubcription({
      type: types.subcriptionById,
      payload: {
        id: arraySubscription[0][0],
        subscriptionEnd: arraySubscription[0][1],
        paqueteName: arraySubscription[0][2],
        subjects: arraySubscription[0][3],
      },
    });
    setLocation("/cancelar/subscripcion");
  };

  const activateSubscription = async (id) => {
    setModalVisibleActivate(false);
    const responseSubcription = await userSubcriptionRepo.statusSubscription({
      id: id,
      status: "ACTIVE",
    });
    setLocation("/dashboard");
  }; 

  const handleModalActivate = (modalVisibleActivate) => {
    setModalVisibleActivate(modalVisibleActivate);
  };

  const onFinishPersonal = () => {
    if (isNaN(formValues.phone) === false) {
      userRepo
        .editUserAll({
          name: formValues.name,
          last_name: formValues.last_name,
          email: formValues.email,
          phone: formValues.phone,
          timezone: formValues.timezone,
          language: formValues.language,
          terms_and_conditions: formValues.terms_and_conditions,
        })
        .then(
          (data) => {
            setUser({
              address_id: data.data.address_id,
              name: data.data.name,
              last_name: data.data.last_name,
              tax_id: data.data.tax_id,
              email: data.data.email,
              phone: data.data.phone,
            });
            Modal.info({
              title: t("info"),
              content: t("msgUpdate"),
            });
            setPersonal(false);
          },
          // GET USER ERROR
          (error) => {
            if (error.response.status === 422) {
              Modal.error({
                title: t("error"),
                content: t("msgRequired"),
              });
            }

            if (error.response.status === 500) {
              Modal.error({
                title: t("error"),
                content: t("msgRequired"),
              });
              setPersonal(false);
            }

            if (error.response.status === 401) {
              Modal.error({
                title: t("error"),
                content: error,
              });
              setLocation("/login");
            }
          }
        );
    } else {
      Modal.error({
        title: t("error"),
        content: "El teléfono debe tener solo valores númericos",
      });
    }
    setLocation("/cuenta/editar");
  };

  const onFinishPassword = () => {
    if (
      formValues.password !== undefined &&
      formValues.passwordNew !== undefined &&
      formValues.confirm !== undefined
    ) {
      userRepo
        .editUserPassword({
          name: formValues.name,
          last_name: formValues.last_name,
          password: formValues.password,
          passwordNew: formValues.passwordNew,
          email: formValues.email,
          phone: formValues.phone,
          timezone: formValues.timezone,
          language: formValues.language,
          terms_and_conditions: formValues.terms_and_conditions,
        })
        .then(
          (data) => {
            setPasswordNew();
            setConfirm();

            Modal.info({
              title: t("info"),
              content: t("msgUpdate"),
            });
            setPassword(false);
          },
          // GET USER ERROR
          (error) => {
            setPasswordNew();
            setConfirm();

            if (error.response.status === 500) {
              Modal.error({
                title: t("error"),
                content: t("msgRequired"),
              });
              setPassword(false);
            }
            if (error.response.status === 400) {
              Modal.error({
                title: t("error"),
                content: error.response.data.message,
              });
              setPassword(false);
            }
            if (error.response.status === 401) {
              Modal.error({
                title: t("error"),
                content: error,
              });
              setLocation("/login");
            }
          }
        );
    } else {
      Modal.error({
        title: t("error"),
        content: t("msgRequired"),
      });
    }
  };

  const onFinishDirection = () => {
    addressRepo
      .editAddress(User.address_id, {
        address_line_1: formValues.address_line_1,
        address_line_2: formValues.address_line_2,
        zip_code: formValues.zip_code,
      })
      .then(
        (data) => {
          setAddress({
            address_line_1: data.data.address_line_1,
            address_line_2: data.data.address_line_2,
            town_id: data.data.town_id,
            zip_code: data.data.zip_code,
          });
          Modal.info({
            title: t("info"),
            content: t("msgUpdate"),
          });
          setDirection(false);
        },
        // GET USER ERROR
        (error) => {
          if (error.response.status === 422) {
            Modal.error({
              title: t("error"),
              content: t("msgRequired"),
            });
          }
          if (error.response.status === 500) {
            Modal.error({
              title: t("error"),
              content: t("msgRequired"),
            });
            setDirection(false);
          }
          if (error.response.status === 401) {
            Modal.error({
              title: t("error"),
              content: error,
            });
            setLocation("/login");
          }
        }
      );
  };

  const onFinishUbication = () => {
    addressRepo
      .editAddress(User.address_id, {
        town_id: town,
      })
      .then(
        (data) => {
          Modal.info({
            title: t("info"),
            content: t("msgUpdate"),
          });
          onLoadUbication(data.data.town_id);
          setUbication(false);
        },
        // GET USER ERROR
        (error) => {
          if (error.response.status === 422) {
            Modal.error({
              title: t("error"),
              content: t("msgRequired"),
            });
          }
          if (error.response.status === 500) {
            Modal.error({
              title: t("error"),
              content: t("msgRequired"),
            });
            setUbication(false);
          }
          if (error.response.status === 401) {
            Modal.error({
              title: t("error"),
              content: error,
            });
            setLocation("/login");
          }
        }
      );
  };

  return (
    <div className="mt-30">
      <Form>
        {/* Row 1 */}
        {editPersonal === true ? (
          <div className="profile-form-row">
            <p className="montserrat-14 mb-5 ">
              <span className="montserrat-14 bold c-blue-primary">
                {t("content1")}
                <CheckOutlined
                  type="primary"
                  style={{ padding: "3px", fontSize: "26px", color: "#0C4393" }}
                  onClick={handlePersonal}
                />
              </span>
            </p>
            <TitleForm />
            <div className="profile-form-row-grid mb-5">
              <Form.Item
                label={
                  <p style={{ fontSize: "20px", color: "red" }}>
                    *
                    <span style={{ fontSize: "14px", color: "#030E1F" }}>
                      {t("nombre")}
                    </span>
                  </p>
                }
                name="name"
                rules={[
                  { pattern: "^[a-zA-Z]*$", message: t("msgcharacters") },
                ]}
              >
                <Input
                  defaultValue={name}
                  key={name}
                  name="name"
                  onChange={handleInputChange}
                />
              </Form.Item>
              <Form.Item
                label={
                  <p style={{ fontSize: "20px", color: "red" }}>
                    *
                    <span style={{ fontSize: "14px", color: "#030E1F" }}>
                      {t("apellido")}
                    </span>
                  </p>
                }
                name="last_name"
                rules={[
                  { pattern: "^[a-zA-Z]*$", message: t("msgcharacters") },
                ]}
              >
                <Input
                  name="last_name"
                  defaultValue={last_name}
                  key={last_name}
                  value={last_name}
                  onChange={handleInputChange}
                />
              </Form.Item>
            </div>

            <div className="profile-form-row-grid mb-5">
              <Form.Item
                label={
                  <p style={{ fontSize: "20px", color: "red" }}>
                    *
                    <span style={{ fontSize: "14px", color: "#030E1F" }}>
                      Rut
                    </span>
                  </p>
                }
                name="tax_id"
              >
                <Input
                  name="tax_id"
                  defaultValue={tax_id}
                  key={tax_id}
                  value={tax_id}
                  disabled
                  onChange={handleInputChange}
                />
              </Form.Item>
              <Form.Item
                label={
                  <p style={{ fontSize: "20px", color: "red" }}>
                    *
                    <span style={{ fontSize: "14px", color: "#030E1F" }}>
                      Email
                    </span>
                  </p>
                }
                name="email"
                rules={[{ type: "email", message: t("msgemailvalid") }]}
              >
                <Input
                  name="email"
                  defaultValue={email}
                  key={email}
                  value={email}
                  onChange={handleInputChange}
                />
              </Form.Item>
            </div>

            <div className="profile-form-row-grid mb-5">
              <Form.Item
                label={
                  <p style={{ fontSize: "20px", color: "red" }}>
                    *
                    <span style={{ fontSize: "14px", color: "#030E1F" }}>
                      No. Móvil
                    </span>
                  </p>
                }
                name="phone"
                rules={[{ pattern: "^[0-9]*$", message: t("msgnumeric") }]}
              >
                <Input
                  name="phone"
                  defaultValue={phone}
                  maxLength={11}
                  key={phone}
                  value={phone}
                  onChange={handleInputChange}
                />
              </Form.Item>
            </div>
            <div className="profile-form-buttons mt-30">
              <Button
                type="primary"
                htmlType="submit"
                onClick={onFinishPersonal}
              >
                {t("btnguardar")}
              </Button>
            </div>
            <TitleForm />
          </div>
        ) : (
          <div className="profile-form-row">
            <p className="montserrat-14 mb-5 ">
              <span className="montserrat-14 bold c-blue-primary">
                {t("content1")}
                <FormOutlined
                  type="primary"
                  style={{ padding: "3px", fontSize: "26px", color: "#0C4393" }}
                  onClick={handlePersonalActive}
                />
              </span>
            </p>
            <TitleForm />
            <div className="profile-form-row-grid mb-5">
              <Form.Item label={t("nombre")}>
                <Input value={name} disabled />
              </Form.Item>
              <Form.Item label={t("apellido")}>
                <Input value={last_name} disabled />
              </Form.Item>
            </div>

            <div className="profile-form-row-grid mb-5">
              <Form.Item label="Rut">
                <Input value={tax_id} disabled />
              </Form.Item>
              <Form.Item label="Email">
                <Input value={email} disabled />
              </Form.Item>
            </div>

            <div className="profile-form-row-grid mb-5">
              <Form.Item label="No. Móvil">
                <Input value={phone} disabled />
              </Form.Item>
            </div>
          </div>
        )}

        {/* ----------------------------------------------------- */}
        {editPassword === true ? (
          <div className="profile-form-row">
            <p className="montserrat-14 mb-5 ">
              <span className="montserrat-14 bold c-blue-primary">
                {t("content4")}
                <CheckOutlined
                  type="primary"
                  style={{ padding: "3px", fontSize: "26px", color: "#0C4393" }}
                  onClick={handlePassword}
                />
              </span>
            </p>
            <TitleForm />
            <div className="profile-form-row-grid mb-5">
              <Form.Item
                label={
                  <p style={{ fontSize: "20px", color: "red" }}>
                    *
                    <span style={{ fontSize: "14px", color: "#030E1F" }}>
                      {t("passwordNow")}
                    </span>
                  </p>
                }
                name="password"
                hasFeedback
                rules={[
                  {
                    pattern:
                      "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.]).{8,}$",
                    message: t("msgpassword1"),
                  },
                ]}
              >
                <Input.Password
                  name="password"
                  value={password}
                  placeholder={t("password")}
                  onChange={handleInputChange}
                  size="large"
                />
              </Form.Item>
              <Form.Item
                label={t("passwordNew")}
                name="passwordNew"
                hasFeedback
                rules={[
                  { required: true, message: t("msgpasswordNew") },
                  {
                    pattern:
                      "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.]).{8,}$",
                    message: t("msgpassword1"),
                  },
                ]}
              >
                <Input.Password
                  defaultValue={passwordNew}
                  name="passwordNew"
                  value={passwordNew}
                  onChange={handleInputChange}
                  placeholder={t("password")}
                  size="large"
                />
              </Form.Item>
            </div>
            <div className="profile-form-row-grid mb-5">
              <Form.Item
                name="confirm"
                label={t("confirmPassword")}
                dependencies={["passwordNew"]}
                hasFeedback
                rules={[
                  { required: true, message: t("msgconfirm") },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("passwordNew") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(t("coincide")));
                    },
                  }),
                ]}
              >
                <Input.Password
                  defaultValue={confirm}
                  name="confirm"
                  value={confirm}
                  onChange={handleInputChange}
                  placeholder={t("repeat")}
                  size="large"
                />
              </Form.Item>
            </div>
            <div className="profile-form-buttons mt-30">
              <Button
                type="primary"
                htmlType="submit"
                onClick={onFinishPassword}
              >
                {t("btnguardar")}
              </Button>
            </div>
          </div>
        ) : (
          <div className="profile-form-row">
            <p className="montserrat-14 mb-5 ">
              <span className="montserrat-14 bold c-blue-primary">
                {t("content4")}
                <FormOutlined
                  type="primary"
                  style={{ padding: "3px", fontSize: "26px", color: "#0C4393" }}
                  onClick={handlePasswordActive}
                />
              </span>
            </p>
            <TitleForm />
          </div>
        )}
        {/* Row 2 */}
        {editDirection === true ? (
          <div className="profile-form-row">
            <p className="montserrat-14 mb-5 ">
              <span className="montserrat-14 bold c-blue-primary">
                {t("content2")}
                <CheckOutlined
                  type="primary"
                  style={{ padding: "3px", fontSize: "26px", color: "#0C4393" }}
                  onClick={handleDirection}
                />
              </span>
            </p>
            <TitleForm />
            <div className="profile-form-row-grid mb-5">
              <Form.Item
                label={
                  <p style={{ fontSize: "20px", color: "red" }}>
                    *
                    <span style={{ fontSize: "14px", color: "#030E1F" }}>
                      {t("direccion1")}
                    </span>
                  </p>
                }
                name="address_line_1"
              >
                <Input
                  name="address_line_1"
                  defaultValue={address_line_1}
                  key={address_line_1}
                  value={address_line_1}
                  onChange={handleInputChange}
                />
              </Form.Item>
              <Form.Item label={t("direccion2")} name="address_line_2">
                <Input
                  name="address_line_2"
                  defaultValue={address_line_2}
                  key={address_line_2}
                  value={address_line_2}
                  onChange={handleInputChange}
                />
              </Form.Item>
            </div>

            <div className="profile-form-row-grid mb-5">
              <Form.Item
                label={
                  <p style={{ fontSize: "20px", color: "red" }}>
                    *
                    <span style={{ fontSize: "14px", color: "#030E1F" }}>
                      {t("postalCode")}
                    </span>
                  </p>
                }
                name="zip_code"
                rules={[{ pattern: "^[0-9]*$", message: t("msgnumeric") }]}
              >
                <Input
                  name="zip_code"
                  defaultValue={zip_code}
                  key={zip_code}
                  value={zip_code}
                  onChange={handleInputChange}
                />
              </Form.Item>
            </div>
            <div className="profile-form-buttons mt-30">
              <Button
                type="primary"
                htmlType="submit"
                onClick={onFinishDirection}
              >
                {t("btnguardar")}
              </Button>
            </div>
          </div>
        ) : (
          <div className="profile-form-row">
            <p className="montserrat-14 mb-5 ">
              <span className="montserrat-14 bold c-blue-primary">
                {t("content2")}
                <FormOutlined
                  type="primary"
                  style={{ padding: "3px", fontSize: "26px", color: "#0C4393" }}
                  onClick={handleDirectionActive}
                />
              </span>
            </p>
            <TitleForm />
            <div className="profile-form-row-grid mb-5">
              <Form.Item label={t("direccion1")}>
                <Input value={address_line_1} disabled />
              </Form.Item>
              <Form.Item label={t("direccion2")}>
                <Input value={address_line_2} disabled />
              </Form.Item>
            </div>

            <div className="profile-form-row-grid mb-5">
              <Form.Item label={t("postalCode")}>
                <Input value={zip_code} disabled />
              </Form.Item>
            </div>
          </div>
        )}
        {editUbication === true ? (
          <div className="profile-form-row">
            <p className="montserrat-14 mb-5 ">
              <span className="montserrat-14 bold c-blue-primary">
                {t("content3")}
                <CheckOutlined
                  type="primary"
                  style={{ padding: "3px", fontSize: "26px", color: "#0C4393" }}
                  onClick={handleUbication}
                />
              </span>
            </p>
            <TitleForm />

            <div className="profile-form-row-grid mb-5">
              <Form.Item
                label={
                  <p style={{ fontSize: "20px", color: "red" }}>
                    *
                    <span style={{ fontSize: "14px", color: "#030E1F" }}>
                      {t("state")}
                    </span>
                  </p>
                }
                name="state"
              >
                <Select
                  name="state"
                  onChange={handleInputSelectState}
                  placeholder={t("state")}
                  size="large"
                >
                  {stateAll.length ? (
                    stateAll.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ))
                  ) : (
                    <Option>{t("msgSubcription13")}...</Option>
                  )}
                </Select>
              </Form.Item>

              <Form.Item
                label={
                  <p style={{ fontSize: "20px", color: "red" }}>
                    *
                    <span style={{ fontSize: "14px", color: "#030E1F" }}>
                      {t("city")}
                    </span>
                  </p>
                }
                name="city"
              >
                <Select
                  name="city"
                  onChange={handleInputSelectCity}
                  placeholder={t("city")}
                  size="large"
                >
                  {cityAll.length ? (
                    cityAll.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ))
                  ) : (
                    <Option>{t("msgSubcription13")}......</Option>
                  )}
                </Select>
              </Form.Item>
            </div>
            <div className="profile-form-row-grid mb-5">
              <Form.Item
                label={
                  <p style={{ fontSize: "20px", color: "red" }}>
                    *
                    <span style={{ fontSize: "14px", color: "#030E1F" }}>
                      {t("town")}
                    </span>
                  </p>
                }
                name="town"
              >
                <Select
                  name="town"
                  onChange={handleInputSelectTown}
                  placeholder={t("town")}
                  size="large"
                >
                  {townAll.length ? (
                    townAll.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ))
                  ) : (
                    <Option>{t("msgSubcription13")}...</Option>
                  )}
                </Select>
              </Form.Item>
            </div>
            <div className="profile-form-buttons mt-30">
              <Button
                type="primary"
                htmlType="submit"
                onClick={onFinishUbication}
              >
                {t("btnguardar")}
              </Button>
            </div>
          </div>
        ) : (
          <div className="profile-form-row">
            <p className="montserrat-14 mb-5 ">
              <span className="montserrat-14 bold c-blue-primary">
                {t("content3")}
                <FormOutlined
                  type="primary"
                  style={{ padding: "3px", fontSize: "26px", color: "#0C4393" }}
                  onClick={handleUbicationActive}
                />
              </span>
            </p>
            <TitleForm />

            <div className="profile-form-row-grid mb-5">
              <Form.Item label={t("state")}>
                <Input value={stateOld} disabled />
              </Form.Item>

              <Form.Item label={t("city")}>
                <Input value={cityOld} disabled />
              </Form.Item>
            </div>
            <div className="profile-form-row-grid mb-5">
              <Form.Item label={t("town")}>
                <Input value={townOld} disabled />
              </Form.Item>
            </div>
          </div>
        )}
        <div className="profile-form-row  ">
          <p className="montserrat-14 mb-5 ">
            <span className="montserrat-14 bold c-blue-primary">
              {t("Subcription")}
            </span>
          </p>
          <TitleForm />
          
          <Table
            style={{overflow: "auto"}}
            columns={columns}
            dataSource={userSubcriptions}
            pagination={true}
            size="middle"
          />
          
          <Modal
            title={t("info")}
            style={{ top: 20 }}
            open={modalVisibleActivate}
            onOk={() => activateSubscription()}
            onCancel={() => handleModalActivate(false)}
          >
            <p>{t("modalActiveSubscription")}</p>
          </Modal>          
        </div>
        <Divider></Divider>
        <div className="profile-form-buttons mt-30">
          <Button onClick={onCancel}>{t("btncancelar")}</Button>
        </div>
      </Form>
    </div>
  );
};

export default FormProfileUser;
