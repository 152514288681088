import { useState, useEffect, useContext } from "react";
import { Button,Input, Form, Modal } from "antd";
import { useForm } from "../../../customHooks/useForm";
import { useLocation } from "wouter";
import TitleForm from "../../Atoms/TitleForm/TitleForm";
import "antd/dist/antd.css";
import useMediaQuery from '@mui/material/useMediaQuery';
import IconButton from '@mui/material/IconButton';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { useTranslation } from "react-i18next";
import {
  userRepo,
  stateRepo,
  townRepo,
  userSubcriptionRepo,
} from "../../../store";
import { AuthContext } from "../../../auth/AuthContext";
import moment from "moment/moment";

const InvoiceDetails = () => {
  const { t } = useTranslation();
  const [, setLocation] = useLocation();
  const isSmallScreen = useMediaQuery( '(max-width: 840px)' );  
  const { subcription } = useContext(AuthContext);
  const [User, setUser] = useState({
    id: "",
    address_id: "",
    name: "",
    last_name: "",
    password: "",
    email: "",
    tax_id: "",
    phone: "",
    address_line_1: "",
    address_line_2: "",
    zip_code: "",
    increase_app_id: "",
  });
  const [subscription, setSubscription] = useState({
    id:"",
    namePackage: "",
    subjects: "",
    dateStartTrial: "",
    dateStartSubscription: "",
    dateEndTrial: "",
    dateEndSubscription: "",
    price: 0,
  });

  const [discount, setDiscount] = useState({
    price: 0,
    subTotal: 0,    
    discount: 0,
    vat: 0,
    total:0,    
  });
  
  const [show, setShow ]= useState(false);

  const [showButton, setShowButton ]= useState(false);

  const [showMessage, setShowMessage] = useState(false);

  const [isDiscount, setIsDiscount ]= useState(false);

  const [Coupon, setCoupon] = useState({
    coupon_code: "",
  });

  const { coupon_code } = Coupon;

  const [formValues, handleInputChange] = useForm();

  const [vat, setVat] = useState();

  const [state, setState] = useState("");

  const [city, setCity] = useState("");

  const [town, setTown] = useState("");
  
  const [modalVisibleSend, setModalVisibleSend] = useState(false);

  const [cleanup, setCleanup] = useState();

  useEffect(() => {
    onload();
    return () => {
      setCleanup();
    };
  }, []);

  const onload = async () => {
    const response = await userRepo.showUser();
    setUser({
      address_id: response.data.address_id,
      name: response.data.name,
      last_name: response.data.last_name,
      tax_id: response.data.tax_id,
      email: response.data.email,
      phone: response.data.phone,
      address_line_1: response.data.address.address_line_1,
      address_line_2: response.data.address.address_line_2,
      zip_code: response.data.address.zip_code,
      increase_id: response.data.increase_app_id
    });
   
    const responseTown = await townRepo.findTown(response.data.address.town_id);
    setTown(responseTown.data.data.name);
    
    setCity(responseTown.data.data.city.name);
    const responseState = await stateRepo.findState(
      responseTown.data.data.city.state_id
    );
    setState(responseState.data.data.name);
    setVat(responseState.data.data.vat);
    userSubcriptionRepo.getUserSubcriptionDetails().then(({ data }) => {
      var start = parseInt(data[0].package.trial_period_days);
      var dateAddStart = moment(data[0].subscription_start).add(start, "days");
      var days = parseInt(data[0].package.trial_period_days) + parseInt(data[0].package.duration);
      var dateAddEnd = moment(data[0].subscription_start).add(days, "days");
      const nameSubjects = Subjects(data[0].subjects);      
      setSubscription({
        id:data[0].id,
        namePackage: data[0].package.name,
        subjects: nameSubjects, 
        dateStartTrial:  moment(data[0].trial_started_at).utc().format("DD/MM/YYYY"),
        dateStartSubscription:  moment( data[0].subscription_start).utc().format("DD/MM/YYYY"),
        dateEndTrial: moment(dateAddStart._d).format("DD/MM/YYYY"),
        dateEndSubscription: 'Válido por '+ data[0].package.duration +' días.',
        price: data[0].package.price,
        subTotal:  data[0].package.price - data[0].payment_tax,
        iva:  data[0].payment_tax,
      });
    });

    setCleanup(false);
  };

  const Subjects = ( subjects) => {
    var arraySubjects = "";    
      for (var j = 0; j < subjects.length; j++) {
        arraySubjects += subjects[j].name + ",";
      }
    return arraySubjects;
  };
  const handleModalSendOk = (modalVisibleSend) => {    
    setModalVisibleSend(modalVisibleSend);
    if(isDiscount === true && discount.total === 0){
      setLocation("/subscripcion/exitosa");
    }else{
      onSendIncrease();
    }
   
  };

  const handleModalSend = (modalVisibleSend) => {
    setModalVisibleSend(modalVisibleSend);
  };

  const onSendIncrease =  (id) => {
    Modal.info({
      title: t("info"),
      content: t("platformPayment"),
    });
    userSubcriptionRepo.createUserSubcriptionIncrease({
      id: id,      
      isDiscount: isDiscount,
    }).then((response) => {
      localStorage.setItem('subscription',id);
      window.open(response.data.data[1], "_blank");
    });    
  };

  const onApplyCoupon = () => {
    if (formValues.coupon_code !== undefined) {
      userSubcriptionRepo
        .applyPromocode(subscription.id,{
          code: formValues.coupon_code,
        })
        .then((data) => {
          if (data.data.promocode_type !== null && data.data.promocode_value !== null) {
            setShowButton(true);
                setShow(true);
                setDiscount({
                  price: data.data.price_net,
                  discount: data.data.discount_value,
                  vat: data.data.vat_value,
                  total: data.data.price_with_discount,
                });              
          } else {
            formValues.coupon_code = undefined;
            Modal.error({
              title: t("error"),
              content: "Cupon no válido",
            });
          }
        });
    }
  };


  const handleMessage = () => {
    setShowMessage(true);
  };

  const paySubscription = (id) => {
    userSubcriptionRepo
      .getUserSubcriptionByIdIncrease({ id: id })
      .then(({ data }) => {
        onloadPay(data[1]);
      });
  };

  const onloadPay = (link) => {
    window.open(link, "_blank");
  };


  const finishStep5 = (e) => {
    // next(); // Voy al siguiente paso
  };

  return (
    <Form onFinish={finishStep5}  style={{background: "white", padding: 10}}>
      <div className="page-controls max-width">
        <div className="mt-40">
          <div className="profile-form-row">
            <p className="montserrat-14 mb-5 ">
              <span className="montserrat-14 bold c-blue-primary">
                {t("content1")}
              </span>
            </p>
            </div>
            <TitleForm />
            <div className="row">
              <div className="col-card col-50">
                <p>
                  {t("nombre")} : {User.name}
                </p>
              </div>
              <div className="col-card col-50">
                <p>
                  {t("Rut")} : {User.tax_id}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-card col-50">
                <p>
                  {t("apellido")} : {User.last_name}
                </p>
              </div>
              <div className="col-card col-50">
                <p>No. Móvil : {User.phone}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-card col-50">
                <p>
                  {t("email")} : {User.email}
                </p>
              </div>
              <div className="col-card col-50">
                <p></p>
              </div>
            </div>
            
            <div className="profile-form-row mt-40">
            <p className="montserrat-14 mb-5 ">
              <span className="montserrat-14 bold c-blue-primary">
                {t("content2")}
              </span>
            </p>
            </div>
            <TitleForm />
            <div className="row">
              <div className="col-card col-50">
                <p>
                  {t("direccion1")} : {User.address_line_1}
                </p>
              </div>
              <div className="col-card col-50">
                <p>
                {t("direccion2")} : {User.address_line_2}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-card col-50">
                <p>
                  {t("state")} : {state}
                </p>
              </div>
              <div className="col-card col-50">
                <p>
                  {t("city")} : {city}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-card col-50">
                <p>
                  {t("town")} : {town}
                </p>
              </div>
              <div className="col-card col-50">
                <p>{t("postalCode")} : {User.zip_code} </p>                
              </div>
            </div>            
            <div className="profile-form-row mt-40">
                <p className="montserrat-14 mb-5 ">
                  <span className="montserrat-14 bold c-blue-primary">
                    {t("service")}
                  </span>
                </p> 
            </div>          
            <TitleForm />
            <div className="row">
              <div className="col-card col-50">
                <p>
                  {t("plan")} : {subscription.namePackage}
                </p>
              </div>
              <div className="col-card col-50">
                <p></p>
              </div>
            </div>
            <div className="row">
              <div className="col-card col-90">
                <p>
                {t("materia")} : {subscription.subjects}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-card col-50">
                <p>{t("dateStartSubscripcion")} : {subscription.dateStartSubscription}</p>
              </div>
              <div className="col-card col-50">
                <p>{t("dateEndSubscripcion")} : {subscription.dateEndSubscription }</p>
              </div>
            </div>
            <div className="row">
              <div className="col-card col-50">
                <p>{t("dateStartTrial")} : {subscription.dateStartTrial }</p>
              </div>
              <div className="col-card col-50">
                <p>{t("dateEndTrial")} : {subscription.dateEndTrial }</p>
              </div>
            </div>  
            <div className="profile-form-row mt-40">
                <p className="montserrat-14 mb-5 ">
                  <span className="montserrat-14 bold c-blue-primary">
                    {t("price")}
                  </span>
                </p>
            </div>
            <TitleForm />
            <div className="row mt-20">
            { subcription.status !== 'pending' ?
            <div className="col-50">
              <div className="row " style={{width:300}}>
                <Form.Item
                  label={t('applyCoupon')}
                  name="coupon_code"
                  rules={[{ required: false, message: t("requiredCoupon") }]}              
                  normalize={(value, prevVal, prevVals) => value.trim()}
                >
                  <Input
                    key={coupon_code}
                    name="coupon_code"
                    onChange={handleInputChange}
                    onFocus={handleMessage}
                  />
                
                </Form.Item>
              
                {showButton === true ?
              isSmallScreen === false ?
              <IconButton
                color="primary"
                aria-label="add coupon"
                disabled
                className="add-icon-button"
                style={{ border: 0, width: 50, marginLeft: -50, marginTop: 28 }}
                size="small"
              >
                <SearchOutlinedIcon />
              </IconButton>
              :
              <IconButton
                color="primary"
                aria-label="add coupon"
                disabled
                className="add-icon-button"
                style={{ border: 0, width: 50, marginLeft: 260, marginTop: -101 }}
                size="small"
              >
                <SearchOutlinedIcon />
              </IconButton>
            :
            isSmallScreen === false ?
              <IconButton
                color="primary"
                aria-label="add coupon"
                onClick={onApplyCoupon}
                style={{  width: 50, marginLeft: -50, marginTop: 28 }}
                size="small"
              >
                <SearchOutlinedIcon />
              </IconButton>
              :
              <IconButton
                color="primary"
                aria-label="add coupon"
                onClick={onApplyCoupon}
                style={{ border: 0, width: 50, marginLeft: 260, marginTop: -101 }}
                size="small"
              >
                <SearchOutlinedIcon />
              </IconButton>
            }
              </div>
              {showMessage === false ? null : (
                <div className="row mt-40">
                  <p>para aplicar el cupón pulsa en el icono   <IconButton><SearchOutlinedIcon  color="primary" size="small" /></IconButton></p>
                </div>
              ) }
            </div>
            :
            null
            }
            {show === false ?
            <div className="col-50">
              <div className="row">
                <div
                  className="col-card"
                  style={{ textAlign: "right", padding: "0 20px" }}
                >
                  <p>
                    {" "}
                    Sub Total : &nbsp;&nbsp;&nbsp;&nbsp; {subscription.subTotal} CLP
                  </p>
                </div>
              </div>
              <div className="row">
                <div
                  className="col-card "
                  style={{ textAlign: "right", padding: "0 20px" }}
                >
                  <p>
                    {" "}
                    Descuento :
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 0 CLP
                  </p>
                </div>
              </div>
              <div className="row">
                <div
                  className="col-card"
                  style={{ textAlign: "right", padding: "0 20px" }}
                >
                  <p>IVA {vat} % : &nbsp;&nbsp;&nbsp;&nbsp; {subscription.iva} CLP</p>
                </div>
              </div>
              <div className="row">
                <div
                  className="col-card"
                  style={{ textAlign: "right", padding: "0 20px" }}
                >
                  <p> Total : &nbsp; {subscription.price} CLP</p>
                </div>
              </div>
            </div>      
            :
            <div className="col-50">
            <div className="row">
              <div
                className="col-card"
                style={{ textAlign: "right", padding: "0 20px" }}
              >
                <p>
                  {" "}
                  Sub Total : &nbsp;&nbsp;&nbsp;&nbsp; {discount.price} CLP
                </p>
              </div>
            </div>
          <div className="row">
              <div
                className="col-card "
                style={{ textAlign: "right", padding: "0 20px" }}
              >
                <p>
                  {" "}
                  Descuento :
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; -{discount.discount } CLP
                </p>
              </div>
            </div>
            <div className="row">
              <div
                className="col-card"
                style={{ textAlign: "right", padding: "0 20px" }}
              >
                <p>IVA {vat} % : &nbsp;&nbsp;&nbsp;&nbsp; {discount.vat} CLP</p>
              </div>
            </div>
            <div className="row">
              <div
                className="col-card"
                style={{ textAlign: "right", padding: "0 20px" }}
              >
                <p> Total : &nbsp; {discount.total} CLP</p>
              </div>
            </div>
            </div>
            }          
        </div>
      </div>
      </div>
      <div className="container-buttons-register">
      <Button onClick={() =>setLocation('/dashboard')}>{t("btnback")}</Button>
      {User.increase_id === null || subcription.status === "Trial"  || subcription.status === "Pending_payment"?
         <Button type="primary" htmlType="submit"    onClick={()=>handleModalSend(true)}>
         {t("toSubscribe")}
       </Button>
     :
     <Button type="primary" htmlType="submit"  onClick={() => paySubscription(subscription.id)}>
          {t("toSubscribe")}
      </Button>
      }   
     </div>
       <Modal
       title={t("info")}
       style={{ top: 20 }}
       open={modalVisibleSend}
       onOk={() => handleModalSendOk(false)}
       onCancel={() => handleModalSend(false)}
     >
       {t("sendSubscription")}
     </Modal>
    </Form>
  );
};
export default InvoiceDetails;
