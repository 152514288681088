import React, { useEffect, useState, useContext } from "react";
import { Card, Modal } from "antd";
import { AuthContext } from "../../../auth/AuthContext";
import { userSubjectStatsRepo } from "../../../store";
import "./ChartGauss.scss";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import bellCurve from "highcharts/modules/histogram-bellcurve"; //module
import { useTranslation } from "react-i18next";
import { useLocation } from "wouter";

bellCurve(Highcharts);

const ChartsBellGauss = () => {
  const { t } = useTranslation();
  const [, setLocation] = useLocation();
  const { user } = useContext(AuthContext);
  const [, setLoadings] = useState();
  const [average, setAverage] = useState(0);
  const [dataGuass, ] = useState([]);
  const [data, setData] = useState([]);
  const [sendingRequest, setSendingRequest] = useState(false);

  useEffect(() => {
    asyncFetch();
    return () => {
      setLoadings();
      setData([]);
    };
  }, []);

  const asyncFetch = () => {
    if (sendingRequest === false) {
      setSendingRequest(true);
      userSubjectStatsRepo.getGaussStats().then(
        ({ data }) => {
          if (data.length !== 0) {
            for (var i = 0; i < data.length; i++) {
              dataGuass.push(parseInt(data[i].percentage));
              if (user.userId === data[i].user) {
                dataGuass.push({
                  y: parseInt(data[i].percentage),
                  marker: {
                    radius: 5,
                    fontFamily: "Montserrat, sans-serif",
                    enabled: true,
                    symbol: "circle",
                    color: "darkred",
                  },
                });
                setAverage(parseInt(data[i].percentage));
              }
            }
          }
          setData(dataGuass);
        },
        (error) => {
          if (error.response.status === 401) {
            Modal.error({
              title: t("error"),
              content: error,
            });
            setLocation("/login");
          } else {
            Modal.error({
              title: t("error"),
              content: error,
            });
          }
        }
      );
    }

    setLoadings(false);
  };

  if (dataGuass.length !== 0 && data.length !== 0) {
    var config = {
      
      title: {
        text: null,
      },
      credits: {
        enabled: false,
      },
      

      xAxis: [
        {
          visible: true,
        },
        {
          title: {
            text: t("puntajeBell"),
          },
        },
        {
          visible: true,
        },
      ],

      yAxis: [
        {
          visible: true,
        },
        {
          title: {
            text: t("posicion"),
          },
          tickInterval: 4,
          opposite: true,
        },
        {
          visible: false,
        },
      ],
     

      series: [
        
        {
          name: t("bellCurve"),
          type: "bellcurve",
          xAxis: 2,
          yAxis: 2,
          baseSeries: "s1",
          zIndex: 2,
          intervals: 4,
          pointsInInterval: 5,
          
         
        },

        {
          name: t("scoreAverage"),
          type: "scatter",
          data: data,
          id: "s1",
          marker: {
            radius: 0,
            fontFamily: "Montserrat, sans-serif",
            enabled: false,
          },

          color: "black",
          zIndex: 3,
          
        },
      ],
    };
  }

  return (
    <Card title={t("titleGauss")} bordered={false}>
      {dataGuass.length !== 0  && data.length !== 0 ? 
      
          <HighchartsReact highcharts={Highcharts} options={config} />          
      
      : null}
    </Card>
  );
};

export default ChartsBellGauss;
