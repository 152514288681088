import { Button, Modal,Steps } from "antd";
import "./SuccessfulSubscription.scss";
import { useEffect, useContext, useState } from "react";
import Logo from "../../Atoms/Logo/Logo";
import { useTranslation } from "react-i18next";
import { useLocation } from "wouter";
import { AuthContext } from "../../../auth/AuthContext";
import { types } from "../../../types/types";
import { userSubcriptionRepo } from "../../../store";
import moment from "moment";
const { Step } = Steps;

const SuccessfulSubscription = () => {
  const { t, i18n } = useTranslation();
  const [, setLocation] = useLocation();
  const { dispatchSubcription } = useContext(AuthContext);
  const [ daysTrial, setDaysTrial] = useState()

  const steps = [
    {
      title: t("registro"),
      status: "finish",
    },
    {
      title: "E-mail",
      status: "finish",
    },
    {
      title: t("materia"),
      status: "finish",
    },
    {
      title: t("direccion"),
      status: "finish",
    },
    {
      title: t("summary"),  
      status: "finish", 
    }, 
    {
      title: t("completed"),
      status: "finish",
    },
  ];

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem("lang"));
    localStorage.setItem("redirect", 1);
    localStorage.setItem("header", 1);        
  },[]);


  const onSend =  () =>{
    var arraySubjects = "";

    userSubcriptionRepo.getUserSubcription().then( ({ data }) => {  
      var status = "";
      var days = 0;
      const dateNow = new Date( moment()._d).toISOString();
      var fecha1 = moment(data[0].trial_started_at);
      var fecha2 = moment(dateNow).add(parseInt(data[0].trial_duration), "days");
      
      days = fecha2.diff(fecha1, "days");
      setDaysTrial(daysTrial);
      status = data[0].status;
      for (var n = 0; n < data[0].subjects.length; n++) {       
        arraySubjects += data[0].subjects[n].name + ",";
      }
      dispatchSubcription({
        type: types.subcription,
        payload: {
          active: 1,
          status: status,
          days: days,
        },
      });

      setLocation('/dashboard');
    },
    (error) => {      
      if (error.response.status === 401) {
        Modal.error({
          title: t("error"),
          content: error,
        });
        setLocation("/login");
      } else {
        Modal.error({
          title: t("error"),
          content: error,
        });
      }
      if (error.response.status === 429) {        
        setLocation("/confirmacion");
      } 
    })
  };

  return (
    <div className="payment_container ">
      <Logo width={"200px"} height={"100%"} customClass="mb-10 text-center" />
      <div className="box-white">
        <Steps style={{ marginRight: 120 }} responsive={"600px"}>
          {steps.map((item) => (
            <Step key={item.title} title={item.title} status={item.status} />
          ))}
        </Steps>

        <h3 className="mb-10 text-center  pt-30 pl-20 pr-20">{t("msgTrial1")}</h3>
        <p className="mb-10  text-center pr-20">{t("msgTrial2")} {daysTrial} </p>
        <p className="mb-10  text-center ">{t("msgTrial3")}</p>
        <p className="mb-10 text-center">{t("msgTrial4")}</p>
           <div className="text-center mt-20 pb-30">        
          <Button type="primary" onClick={() => onSend()}>
            {t("btncontinue")}
          </Button>
        
        </div>
      </div>
    </div>
  );
};

export default SuccessfulSubscription;
