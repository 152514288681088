import { useState, useEffect, useContext } from "react";

import HeaderEnsayoPage from "../../Organisms/HeaderPage/HeaderEnsayoPage";

import CardTableHistorico from "../../Molecules/CardTableHistorico/CardTableHistorico";

import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../auth/AuthContext";
import { userTestRepo } from "../../../store";

import "./Historical.scss";

const HistoricalEnsayo = () => {
  const { t } = useTranslation();
  const { typeTest, control } = useContext(AuthContext);
  const [dataEnsayo, setDataEnsayo] = useState([]);
  const [loadings, setLoadings] = useState();
  localStorage.removeItem("render");

  const columns = [
    {
      title: "",
      dataIndex: "select",
      key: "select",
    },
    {
      title: t("date"),
      dataIndex: "date",
      key: "date",
      defaultSortOrder: "ascend",
      sorter: (a, b) => new Date(a.date) - new Date(b.date),    
    },
    {
      title: typeTest.type === "custom" ? t("note") : t("score"),
      dataIndex: "note",
      key: "note",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.note - b.note,
    },
    {
      title: t("Total"),
      dataIndex: "total",
      key: "total",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.total - b.total,
    },
    {
      title: t("correctQuestion"),
      dataIndex: "correct",
      key: "correct",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.correct - b.correct,
    },
    {
      title: t("result"),
      dataIndex: "result",
      key: "result",
    },
    {
      title: t("tiempo") +' minutos',
      dataIndex: "time",
      key: "time",      
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.time - b.time,
    },
  ];

  useEffect(() => {
    onLoad();
    return () => {
      setLoadings();
    };
  }, []);

  const onLoad = async () => {
    const response = await userTestRepo.getUserTest(control.subjectId);

    for (var i = 0; i < response.data.data.length; i++) {
     if (response.data.data[i].type === "official") {
        setDataEnsayo((dataEnsayo) => [
          ...dataEnsayo,
          {
            key: response.data.data[i].id,
            date: response.data.data[i].fecha,
            note: Math.round(response.data.data[i].score),
            total: response.data.data[i].total_test_stems,
            correct: response.data.data[i].total_correct_choices,
            result: response.data.data[i].score >= control.subjectApprovalGrade
                    ? t("aprobado")
                    : t("reprobado"),
            time: response.data.data[i].min_passed

          },
        ]);
      }
    }
    setLoadings(false);
  };

  return (
    <div className="max-width">
      
        <HeaderEnsayoPage
          withMenu
          contentButton={t("contentButtonHistorical")}
          routeButton="/ensayo"
          contentTitle={t("contentTitleEnsayo")}
          contentSubtitle={
            t("contentSubtitleHistorical") + " " + control.subjectName
          }
        />
      
      
        <CardTableHistorico
          customClass="mt-20 cols-2"
          columns={columns}
          data={dataEnsayo}
        />
      
    </div>
  );
};

export default HistoricalEnsayo;
