import ActiveLinkD from "../../Atoms/ActiveLinkD/ActiveLinkD";
import { useTranslation } from "react-i18next";
import "./MainMenu.scss";

const MainMenu = () => {
  const { t } = useTranslation();
  

  return (
    <nav className="main-menu">
      <ul className="main-menu_list">        
        <ActiveLinkD href="/dashboard" type={""}>
          Dashboard
        </ActiveLinkD>
        <ActiveLinkD href="/control" type={"custom"}>
          {t("control")}
        </ActiveLinkD>
        <ActiveLinkD href="/ensayo" type={"official"}>
          {t("ensayo")}
        </ActiveLinkD>      
            
      </ul>
    </nav>
  );
};

export default MainMenu;
